import React from "react";
import { useTranslation } from "react-i18next";

const ShareAbleMode = ({ mode, handleMode }) => {
  const { t } = useTranslation();

  return (
    <div className="d-flex text-center gap-1 fs-14">
      <button
        name="manual"
        className={`w-100 py-3 radius-3 orbitron-font ${
          mode === "manual" ? "dark-bg-two secondary_text" : "text_color"
        }`}
        onClick={handleMode}
      >
        {t("header.referral.manual")}
      </button>
      <button
        name="auto"
        className={`w-100 py-3 radius-3 orbitron-font ${
          mode === "auto" ? "dark-bg-two secondary_text" : "text_color"
        }`}
        onClick={handleMode}
      >
        {t("header.referral.auto")}
      </button>
    </div>
  );
};

export default ShareAbleMode;
