// React Imports
import { useState, useEffect, useContext } from "react";
// Redux imports
import { useDispatch, useSelector } from "react-redux";

import { setUserWalletAmount, setUserWallets } from "../../../store/user";

import useSound from "use-sound";

import StartSound from "./Sound/start.mp3";
import WinSound from "./Sound/win.mp3";
import loseSound from "./Sound/lose.mp3";
import PlinkoBgSound from "./Sound/plinko-bg.mp3";

// CSS Imports
import "./Plinko.css";
// Components Import
import Playground from "./Playground/Playground";
import Playarea from "./Playarea/Playarea";
import BetStats from "./Stats/Stats";
// Context import
import { betPriceContext } from "../../Contexts/BetPrice";
import { modalsControl } from "../../Contexts/ModalsControl";
// APis import
import PlinkoApis from "../../../api/Plinko/plinko";
import BetConfigration from "../../../api/currency_bet_configrations";
import PoolAPI from "../../../api/pool";
import UserWalletsAPI from "../../../api/user_wallets";
//action cable exclusive imports
import UserChannel from "../../../channels/user_channel";

const Plinko = () => {
  const [BallStartSound] = useSound(StartSound);
  const [BallSound] = useSound(WinSound);
  const [BallLoseSound] = useSound(loseSound);
  const [PlinkoSound] = useSound(PlinkoBgSound);
  // Helper Variables
  const inputInitials = {
    betAmount: 1,
    row: 8,
    risk: 0,
    rowsArray: [],

    // auto bet value
    autoBets: 0,
  };
  const dispatch = useDispatch();
  // Helper functions
  const generateArray = (length) => {
    let array = [];
    for (let i = 0; i < length; i++) {
      array.push(i + 1);
    }
    return array;
  };

  // Context States
  const { activatedCurrency } = useContext(betPriceContext);
  const { setSignInOpen, showTrends, setShowTrends } =
    useContext(modalsControl);
  // Controlled States
  const [betValues, setBetValues] = useState(inputInitials);
  const [directions, setDirections] = useState([]);
  const [binLocation, setBinLocation] = useState(0);
  const [myBets, setMyBets] = useState([]);
  const [pool_amounts, setPoolAmounts] = useState([]);
  const [selectedPool, setSelectedPool] = useState([]);
  const [inProgressBet, setInprogressBet] = useState(false);
  const [droppingBalls, setDroppingBalls] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currencyConfigrations, setCurrencyConfigrations] = useState([]);
  const [configuredCurrency, setConfiguredCurrency] = useState({});
  const [alertMessage, setAlertMessage] = useState("");

  const [autoBetInProgress, setAutoBetInProgress] = useState(false);
  const [autoBetActive, setAutoBetActive] = useState(0);

  // Redux States
  const user_id = useSelector((state) => state.user.currentUser?.user_id);

  const findConfiguredCurrency = () => {
    setConfiguredCurrency(
      currencyConfigrations?.find(
        (currency) => currency.network_id === activatedCurrency.network_id
      )
    );
  };
  const fetchPoolAmounts = () => {
    PoolAPI.getPoolAmounts("plinko").then((response) => {
      setPoolAmounts(response?.data?.pool_values);
    });
  };

  const fetMyBets = () => {
    setIsLoading(true);
    PlinkoApis.fetch_bets()
      .then((res) => {
        setIsLoading(false);
        setMyBets(res.data.data.user_bets);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const handleAutoButtonClick = () => {
    setAutoBetInProgress(!autoBetInProgress);
  };

  const createBet = () => {
    if (!user_id) {
      setSignInOpen(true);
      return;
    }
    if (inProgressBet) {
      return;
    }
    setInprogressBet(true);
    setDroppingBalls(true);
    PlinkoApis.create_bet({
      plinko: {
        amount: betValues.betAmount,
        network_id: activatedCurrency.network_id,
        rows: parseInt(betValues.row),
        risk: parseInt(betValues.risk),
      },
    })
      .then((res) => {
        setTimeout(() => {
          setAutoBetActive(autoBetActive + 1);
        }, 1000);
      })
      .catch((err) => {
        setInprogressBet(false);
        setDroppingBalls(false);
        setAlertMessage(err?.response?.data?.message);
        setTimeout(() => {
          setAlertMessage("");
        }, 3000);
      });
  };

  useEffect(() => {
    UserChannel.received = (data) => {
      if (
        data.type === "plinko_create_bet_data" &&
        data.data.bet_status &&
        data.data.user_id == user_id
      ) {
        dispatch(
          setUserWalletAmount({
            network_id: data.data.bet_history.network_id,
            amount: data.data.user_wallet_bet_deduction,
          })
        );
        BallStartSound();
        setTimeout(() => {
          setInprogressBet(false);
        }, 300);
        setDirections((directions) => [...directions, data.data]);

        setTimeout(() => {
          if (data.data.bet_history.win_status) {
            BallSound();
          } else {
            BallLoseSound();
          }
        }, 400 * betValues.row);
      } else if (
        data.type === "plinko_create_bet_data" &&
        data.data.bet_status === false &&
        data.data.user_id == user_id
      ) {
        //handle plinko bet didnt place errors/checks
        setInprogressBet(false);
      }
    };
  }, []);

  useEffect(() => {
    setBetValues({ ...betValues, rowsArray: generateArray(betValues.row) });
  }, [betValues.row]);
  useEffect(() => {
    BetConfigration.bet_configrations("limbo")
      .then((res) => {
        setCurrencyConfigrations(res.data.currency_configuration);
      })
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    findConfiguredCurrency();
  }, [currencyConfigrations.length, activatedCurrency.network_id]);
  useEffect(() => {
    if (!user_id) {
      setMyBets([]);
      return;
    }
    fetMyBets();
  }, [user_id]);
  useEffect(() => {
    fetchPoolAmounts();
  }, []);
  useEffect(() => {
    setSelectedPool(
      pool_amounts?.find(
        (element) => element.network_id === activatedCurrency?.network_id
      )
    );
  }, [activatedCurrency?.network_id, pool_amounts]);

  

  useEffect(() => {
    document.title =
      "Plinko Money Game: Win real money with Plinko - drop, bounce, and get exciting rewards!";
  }, []);

  useEffect(() => {
    if (
      parseInt(autoBetActive) < parseInt(betValues.autoBets) &&
      autoBetInProgress &&
      parseInt(betValues.autoBets) - autoBetActive > 0
    ) {
      createBet();
    } else if (autoBetInProgress && parseInt(betValues.autoBets) == 0) {
      createBet();
    } else {
      setAutoBetInProgress(false);
      setAutoBetActive(0);
    }
  }, [autoBetActive, autoBetInProgress]);
  
  useEffect(() => {
    if (showTrends) {
      setShowTrends(false);
    }
  }, []);
 
  useEffect(() => {
    document.title =
      "Plinko Money Game: Win real money with Plinko - drop, bounce, and get exciting rewards!";
  }, []);
  return (
    <div className="main-wrapper-container">
      <div className="row m-0">
        <Playground
          directions={directions}
          betValues={betValues}
          generateArray={generateArray}
          myBets={myBets}
          selectedPool={selectedPool}
          symbol={activatedCurrency.symbol}
          networkId={activatedCurrency.network_id}
          setDirections={setDirections}
          binLocation={binLocation}
          fetchBetsHistory={fetMyBets}
          setBinLocation={setBinLocation}
          setMyBets={setMyBets}
          setInprogressBet={setInprogressBet}
          fetchPoolAmounts={fetchPoolAmounts}
          droppingBalls={droppingBalls}
          setDroppingBalls={setDroppingBalls}
        />
        <Playarea
          betValues={betValues}
          setBetValues={setBetValues}
          createBet={createBet}
          inProgressBet={inProgressBet}
          configuredCurrency={configuredCurrency}
          alertMessage={alertMessage}
          droppingBalls={droppingBalls}
          autoBetActive={autoBetActive}
          setAutoBetActive={setAutoBetActive}
          autoBetInProgress={autoBetInProgress}
          handleAutoButtonClick={handleAutoButtonClick}
        />
      </div>
      <div className="crashGame-history-container">
        <BetStats myBets={myBets} isLoading={isLoading} />
      </div>
    </div>
  );
};

export default Plinko;
