import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { betPriceContext } from "../../Contexts/BetPrice";
import ShowComponent from "../../Common/ShowComponent/ShowComponent";
import axios from "axios";
import BetConfigration from "../../../api/currency_bet_configrations";

import Stats from "./Stats/Stats";
import providerHistoryAPI from "../../../api/Provider/bet_history";

import ProviderApis from "../../../api/Provider";
import { useSelector, useDispatch } from "react-redux";
import UserWalletsAPI from "../../../api/user_wallets";
import { setCashedOut, setUserWallets } from "../../../store/user";
import { multiplierContext } from "../../Contexts/Multiplier";

import { useSearchParams } from "react-router-dom";
import { Socket } from "socket.io-client";

import Switch from "../../Common/Header/Wallet/Currencies/Switch/Switch";
const Provider = () => {
  const { clientSocket } = useContext(multiplierContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const [myChannel, setMyChannel] = useState(null);

  const createBetListnerCallback = ({
    betAmount,
    betMines,
    mode,
    betFields,
    initialAmount,
    currency_rate,
    selectedTiles,
    networkId,
    update,
  }) => {
    // console.log(`createBetListnerCallback: ${betAmount}, ${betMines}`);
    createBet(
      betAmount,
      betMines,
      mode,
      betFields,
      initialAmount,
      currency_rate,
      selectedTiles,
      networkId,
      update
    );
  };

  const cashoutBetListnerCallback = ({
    casino_operator_bet_id,
    provider_bet_id,
    currentMultiplier,
    networkId,
  }) => {
    // debugger
    handleCashout(
      casino_operator_bet_id,
      provider_bet_id,
      currentMultiplier,
      networkId
    );
  };

  const subscribeChannel = (channelId) => {
    let channel = clientSocket.subscribe(channelId);
    setMyChannel(channel);
  };

  const dispatch = useDispatch();
  const user_id = useSelector((state) => state.user.currentUser?.user_id);
  const { activatedCurrency } = useContext(betPriceContext);

  const { t } = useTranslation();
  const [myBets, setMyBets] = useState([]);

  const mines = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24,
  ];
  const [configuredCurrency, setConfiguredCurrency] = useState({});
  const [currencyConfigrations, setCurrencyConfigrations] = useState([]);
  const [uuid, setUUID] = useState();

  // Helper functions
  const findConfiguredCurrency = () => {
    setConfiguredCurrency(
      currencyConfigrations?.find(
        (currency) => currency.network_id === activatedCurrency.network_id
      )
    );
  };
  const inputInitials = {
    betAmount: 1,
    mines: 1,
    network_id: activatedCurrency.network_id,
    autoBets: 0,
  };
  const [betValues, setBetValues] = useState(inputInitials);
  const [betId, setBetId] = useState(null);
  const [providerBetId, setProviderBetId] = useState(null);
  const [betMines, setMines] = useState(1);
  const [currentMultiplier, setCurrentMultiplier] = useState(1);
  const [nextMultiplier, setnextMultiplier] = useState(1);
  const [inprogressBet, setInprogressBet] = useState(false);
  const [showSlider, setShowSlider] = useState(false);
  const [tokenSent, setTokenSent] = useState(false);
  const [disabledCashout, setDisabledCashout] = useState(true);
  const [autoPlacedBets, setAutoPlacedBets] = useState(0);

  const [mode, setMode] = useState("manual");

  const setToken = () => {
    setTokenSent(true);
    sendData({
      message: "auth token",
      type: "authToken",
      value: {
        auth: gamesData.token,
        mines: betMines,
        amount: betValues.betAmount,
        walletAmount: activatedCurrency.fake_amount,
        network_id: activatedCurrency.network_id,
      },
    });

    // sendData({
    //   message: "wallet info",
    //   type: "getWallet",
    //   value: {
    //     walletAmount: activatedCurrency.fake_amount,
    //     network_id: activatedCurrency.network_id,
    //   },
    // });

    sendData({
      message: "Game history",
      type: "betsHistory",
      value: { myBets },
    });
  };
  const handleOnChange = (e) => {
    if (inprogressBet) {
      return;
    }
    const { name, value } = e.target;

    setBetValues({ ...betValues, [name]: value });
  };
  const handleToggleSlider = () => {
    if (inprogressBet) {
      return;
    }
    setShowSlider(!showSlider);
  };
  const handleBetAmountBlur = (e) => {
    const { name, value } = e.target;
    if (!value || parseFloat(value) < 0) {
      minBetSetter();
    } else if (parseFloat(value) < configuredCurrency?.min_bet) {
      minBetSetter();
    } else if (parseFloat(value) > configuredCurrency?.max_bet) {
      maxBetSetter();
    } else {
      setBetValues({
        ...betValues,
        [name]: parseFloat(value).toFixed(configuredCurrency?.precision),
      });
    }
  };
  const minBetSetter = () => {
    setBetValues({
      ...betValues,
      betAmount: parseFloat(configuredCurrency?.min_bet).toFixed(
        configuredCurrency?.precision
      ),
    });
  };
  const maxBetSetter = () => {
    setBetValues({
      ...betValues,
      betAmount: parseFloat(configuredCurrency?.max_bet).toFixed(
        configuredCurrency?.precision
      ),
    });
  };
  const multiplyBetAmount = () => {
    if (inprogressBet) {
      return;
    }
    if (parseFloat(betValues?.betAmount) * 2 < configuredCurrency?.max_bet) {
      setBetValues({
        ...betValues,
        betAmount: (parseFloat(betValues?.betAmount) * 2).toFixed(
          configuredCurrency?.precision
        ),
      });
    } else {
      maxBetSetter();
    }
  };
  const divideBetAmount = () => {
    if (inprogressBet) {
      return;
    }
    if (parseFloat(betValues?.betAmount) / 2 > configuredCurrency?.min_bet) {
      setBetValues({
        ...betValues,
        betAmount: (parseFloat(betValues?.betAmount) / 2).toFixed(
          configuredCurrency?.precision
        ),
      });
    } else {
      minBetSetter();
    }
  };
  const handleMinBetAmount = () => {
    minBetSetter();
  };
  const handleMaxBetAmount = () => {
    maxBetSetter();
  };
  const [gamesData, setGamesData] = useState({});
  const [loadingGame, setLoadingGame] = useState(false);
  const onChange = (e) => {
    const { name, value } = e.target;
    if (name === "mines") {
      sendData({
        message: "bet mines",
        type: "betFieldsSend",
        value: value,
      });
    }

    setMines(value);
  };
  const handleMode = (e) => {
    const { id } = e.target;
    setMode(id);
    sendData({
      message: "Game Mode Info",
      type: "gameMode",
      value: {
        mode,
      },
    });
  };
  // Event sender
  const sendData = (eventData) => {
    // console.log("eventData ::: ", eventData);
    // const iframe1 = document.getElementById("iframe1");
    // iframe1.contentWindow.postMessage(
    //   eventData,
    //   process.env.REACT_APP_PROVIDER_EVENTS
    // );
    // debugger
    eventData.from = "agent";
    // debugger
    myChannel.transmitPublish(eventData);
  };
  const sendDataToSocket = (eventData) => {
    myChannel.transmitPublish(eventData);
  };
  const BetFunctionResponse = (data) => {
    setInprogressBet(data.inProgressBet);
    setCurrentMultiplier(data.currentMultiplier);
    setnextMultiplier(data.nextMultiplier);
    if (data.selectedMines) {
      setMines(data.selectedMines);
    }
    if (data.casinoBetId) {
      setBetId(data.casinoBetId);
    }
    if (data.providerBetId) {
      setProviderBetId(data.providerBetId);
    }
    if (data.triggerCashout) {
      handleCashout(data.casinoBetId, data.providerBetId);
    }
  };

  // const cashoutEnable = (data) => {
  //   setDisabledCashout(data.cashoutEnable);
  // };

  // Availiable response
  const initialData = () => {
    sendData({
      message: "wallet info",
      type: "getWallet",
      value: {
        walletAmount: activatedCurrency.fake_amount,
        network_id: activatedCurrency.network_id,
      },
    });
  };
  const availableResponse = {
    betFunctions: (data) => BetFunctionResponse(data),
    createBet: (value) => createBetListnerCallback(value),
    cashout: (value) => cashoutBetListnerCallback(value),
    tokenRequest: () => setToken(),
    initialData: (value) => initialData(value),
  };
  // Event receiver
  const receiveMessageFromChild = (data) => {
    // debugger
    const { type, value, from } = data;
    // console.log("Date received from child", data);
    if (type && from != "agent") {
      if (!availableResponse[type]) {
        return;
      }
      availableResponse[type](value);
    }
  };
  useEffect(() => {
    if (myChannel?.name) {
      console.log(
        "activated wallet for sendng ::: ",
        activatedCurrency.network_id
      );
      sendData({
        message: "wallet info",
        type: "getWallet",
        value: {
          walletAmount: activatedCurrency.fake_amount,
          network_id: activatedCurrency.network_id,
        },
      });
    }
  }, [
    activatedCurrency.fake_amount,
    activatedCurrency.network_id,
    myChannel?.name,
  ]);
  useEffect(() => {
    if (tokenSent) {
      sendData({
        message: "bet amount",
        type: "betFieldsSend",
        value: betValues.betAmount,
      });
    }
  }, [betValues.betAmount]);

  useEffect(() => {
    if (gamesData.url) {
      const url = new URL(gamesData.url);
      const params = url.searchParams;
      const uuid = params.get("channelId");
      // debugger
      subscribeChannel(uuid);
    }
  }, [gamesData]);

  // Event publisher effect

  const createBet = (
    betAmount,
    betMines,
    mode,
    betFields,
    initialAmount,
    currency_rate,
    selectedTiles,
    networkId,
    update
  ) => {
    // debugger

    ProviderApis.create_bet({
      provider_bet: {
        game: "mines",
        amount: parseFloat(betAmount),
        network_id: networkId,
        update: update,
      },
    })
      .then((res) => {
        setDisabledCashout(true);
        setBetId(res.data.casino_operator_bet_id);
        sendData({
          message: "Create the bet",
          type: "createBet",
          value: {
            betStatus: true,
            mode,
            betMines,
            auth: gamesData.token,
            casinoBetId: res.data.casino_operator_bet_id,
            betAmount,
            betFields,
            initialAmount,
            currency_rate,
            selectedTiles,
          },
        });

        UserWalletsAPI.getUserWallets()
          .then((response) => {
            dispatch(
              setUserWallets({
                wallets: response.data,
              })
            );
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => {
        // alert(err.response.data.msg);
        if (err.response.data.msg === "bet already created") {
          sendData({
            message: "Create the bet",
            type: "reCreateTheBet",
            value: {
              betStatus: false,
              mode,
              update: true,
              betMines,
              auth: gamesData.token,
              betAmount,
              betFields,
              initialAmount,
              currency_rate,
              selectedTiles,
              networkId,
            },
          });
        } else {
          sendData({
            message: "Create the bet",
            type: "createBet",
            value: {
              betStatus: false,
              mode,
            },
          });
        }
      });
  };
  const gameBetHistory = () => {
    // setLoadingGame(true);
    providerHistoryAPI
      .providerGameHistory()
      .then((res) => {
        setMyBets(res.data.data.mines_bets);
        // setLoadingGame(false);
      })
      .catch((err) => {
        // setLoadingGame(false);
        console.log(err);
      });
  };
  const handleCashout = (
    PbetId,
    PproviderBetId,
    currentMultiplier,
    networkId
  ) => {
    ProviderApis.cashout({
      provider_bet: {
        game: "mines",
        casino_operator_bet_id: PbetId ? PbetId : betId,
        provider_bet_id: PproviderBetId ? PproviderBetId : providerBetId,
        multiplier: parseFloat(currentMultiplier),
      },
    })
      .then((res) => {
        sendData({
          message: "casino bet cashedout successfull",
          type: "cashedOutSuccess",
          value: {
            auth: gamesData.token,
          },
        });

        sendData({
          message: "wallet info",
          type: "getWallet",
          value: {
            walletAmount: res.data.user_wallet_amount,
            network_id: networkId,
          },
        });

        UserWalletsAPI.getUserWallets()
          .then((response) => {
            dispatch(
              setUserWallets({
                wallets: response.data,
              })
            );
          })
          .catch((err) => console.log(err));
        providerHistoryAPI
          .providerGameHistory()
          .then((res) => {
            setMyBets(res.data.data.mines_bets);
            sendData({
              message: "Game history",
              type: "betsHistory",
              value: { myBets: res.data.data.mines_bets },
            });

            // setLoadingGame(false);
          })
          .catch((err) => {
            // setLoadingGame(false);
            console.log(err);
          });

        sendData({
          message: "agent cashout successfull",
          type: "agentCashout",
          value: {
            agentCashout: true,
          },
        });
      })
      .catch((err) =>
        sendData({
          message: "agent cashout successfull",
          type: "agentCashout",
          value: {
            agentCashout: false,
          },
        })
      );
  };

  const handleAutoBet = () => {
    ProviderApis.create_bet({
      provider_bet: {
        game: "mines",
        amount: parseFloat(betValues.betAmount),
        network_id: activatedCurrency.network_id,
      },
    })
      .then((res) => {
        setDisabledCashout(true);
        setBetId(res.data.casino_operator_bet_id);
        sendData({
          message: "Create the bet",
          type: "createAutoBet",
          value: {
            betMines,
            auth: gamesData.token,
            casinoBetId: res.data.casino_operator_bet_id,
            betAmount: betValues.betAmount,
          },
        });
        // sendData({
        //   message: "hide boxes",
        //   type: "hideBoxes",
        //   value: {
        //     auth: gamesData.token,
        //   },
        // });
        UserWalletsAPI.getUserWallets()
          .then((response) => {
            dispatch(
              setUserWallets({
                wallets: response.data,
              })
            );
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  };
  const autoBetsOnChange = (e) => {
    const { value } = e.target;
    setBetValues({
      ...betValues,
      autoBets: parseInt(value),
    });
    if (autoPlacedBets) {
      setAutoPlacedBets(0);
    }
  };
  const handleAutoBetsNumber = (e) => {
    const { name } = e.target;

    setBetValues({
      ...betValues,
      autoBets: parseInt(name),
    });
    setAutoPlacedBets(0);
  };
  const stopLossBlurHandle = (e) => {
    if (!e.target.value || parseFloat(e.target.value) < 0) {
      setBetValues({
        ...betValues,
        [e.target.name]: 0,
      });
    }
  };
  const autoBetsOnBlur = (e) => {
    const { value } = e.target;
    if (!value) {
      setBetValues({
        ...betValues,
        autoBets: 10,
      });
    }
    if (autoPlacedBets) {
      setAutoPlacedBets(0);
    }
  };

  useEffect(() => {
    if (user_id) {
      gameBetHistory();
    }
  }, [user_id]);
  useEffect(() => {
    if (user_id) {
      setLoadingGame(true);
      axios
        .post(
          `${process.env.REACT_APP_PROVIDER_LINK}/api/v1/provider/get_game`,
          {
            provider_data: {
              game: {
                name: "mines",
                variant: "land",
              },
              user_id: user_id,
              api_token: `${process.env.REACT_APP_PROVIDER_KEY}`,
            },
          }
        )
        .then((response) => {
          setGamesData(response.data.data);
          setLoadingGame(false);
          setTimeout(() => {}, 9000);
        })
        .catch((err) => {
          setLoadingGame(false);
          console.warn(err);
        });
    }
  }, [user_id]);
  useEffect(() => {
    BetConfigration.bet_configrations("mines")
      .then((res) => {
        setCurrencyConfigrations(res.data.currency_configuration);
      })
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    findConfiguredCurrency();
  }, [currencyConfigrations.length, activatedCurrency.network_id]);
  useEffect(() => {
    if (!configuredCurrency?.min_bet) {
      return;
    }
    minBetSetter();
  }, [configuredCurrency?.min_bet, activatedCurrency.network_id]);

  useEffect(() => {
    // window.addEventListener("message", (event) =>
    //   receiveMessageFromChild(event.data)
    // );
    // return () => {
    //   window.removeEventListener("message", (event) =>
    //     receiveMessageFromChild(event.data)
    //   );
    // };
  }, [myChannel]);

  useEffect(() => {
    if (!myChannel) {
      return;
    } else {
      (async () => {
        for await (let data of myChannel) {
          if (data.from != "agent") {
            // debugger;
            // console.log("Message received from child ::: ", data);
            receiveMessageFromChild(data);
          }
        }
      })();
    }
  }, [myChannel]);

  return (
    <>
      <div className="main-wrapper-container">
        <div className="col-lg-12 mb-4 p-0 dark-bg-four">
          {user_id ? (
            loadingGame ? (
              <div className="justified height text_color orbitron-font">
                Loading game ...
              </div>
            ) : !gamesData.url ? (
              <div className="justified height text_color orbitron-font">
                Unable to load the game
              </div>
            ) : (
              <div className="relative">
                <iframe
                  id="iframe1"
                  src={gamesData.url}
                  // src="http://localhost:5173/mines/land?channelId=channelId"
                  title="Microfrontend"
                  height="850px"
                ></iframe>
                {/* {console.log(gamesData.url)} */}
              </div>
            )
          ) : (
            <div className="justified height text_color orbitron-font">
              You need to signin
            </div>
          )}
        </div>
      </div>
      <div className="crashGame-history-container">
        <Stats myBets={myBets} loadingGame={loadingGame} />
      </div>
    </>
  );
};

export default Provider;
