// React imports
import React, { useState, useEffect } from "react";
// APi imports
import PlinkoApis from "../../../../api/Plinko/plinko";
// Components import
import ShowComponent from "../../../Common/ShowComponent/ShowComponent";
import BallMap from "./BallMapper/BallMap";
import PlinkoPopup from "../Playarea/PlinkoPopup"
import { useTranslation, Trans } from "react-i18next";

const Playground = ({
  directions,
  setDirections,
  betValues,
  generateArray,

  myBets,
  symbol,
  selectedPool,
  fetchBetsHistory,
  binLocation,
  networkId,
  setBinLocation,
  setMyBets,
  setInprogressBet,
  fetchPoolAmounts,
  droppingBalls,
  setDroppingBalls,
}) => {
  // Controlled States
  const [betId, setBetId] = useState(null);
  const [plinkoModal, setPlinkoModal] = useState(false);
  const [bonusLine, setBonusLine] = useState("");
  const [loading, setLoading] = useState(false);
  const [dataDependent, setDataDependent] = useState(false);
  const [pyramid, setPyramid] = useState([]);
  const { t } = useTranslation();
  const handleVerifyModal = (e) => {
    const { id } = e.target;
    setBetId(id);
    setPlinkoModal(true);
  };
  const fetchBonusLine = () => {
    setLoading("loading");
    setDirections([]);
    PlinkoApis.fetch_bonusline(betValues.row, betValues.risk)
      .then((res) => {
        setBonusLine(res.data.data.bonus_line);
        setLoading("completed");
      })
      .catch((err) => {
        setLoading("completed");
      });
  };

  useEffect(() => {
    if (loading === "completed") {
      setDataDependent(betValues.row);
      setPyramid(betValues.rowsArray);
    }
  }, [loading]);
  useEffect(() => {
    fetchBonusLine();
  }, [betValues.row, betValues.risk]);

  return (
    <div className="col-lg-9 mb-4 p-0 dark-bg-four">
      <div className="plinko-game p-3 pb-0">
        {/* <div className="plinko-stars">
          {generateArray(50).map((star) => (
            <div className="plinko-star"></div>
          ))}
        </div> */}
        <div className="align-content-center d-flex justify-content-start position-relative z-index-1">
          <div className="bankroll d-flex dark-bg-two flex-column justify-content-center me-2 p-1 px-4 radius-4 text-center">
            <p className="d-flex gap-2 justify-content-center mb-1 orbitron-font text-success">
              {t("header.referral.bankroll")}
              <span className="text-color-secondary uppercase">{symbol}</span>
            </p>
            <p className="mb-0 text-white d-flex justify-content-center gap-2">
              {selectedPool?.amount}
              <img
                src={`/imgs/AcceptedNetworks/${networkId}.webp`}
                alt="BetCoin"
                className="currency-icons top-0"
              />
            </p>
          </div>
          <ul className="align-items-center d-flex flex-fill justify-content-end games-trends-bar gap-2 orbitron-font fs-14">
            {!myBets.length ? (
              <li className="dark-bg-one">{t("header.sidebar.BETHISTORY")}</li>
            ) : (
              myBets.map((bet, index) =>
                index < 7 ? (
                  <li
                    id={bet?.id}
                    className={bet.win_status ? "bg-blue" : "dark-bg-one"}
                    onClick={handleVerifyModal}
                  >
                    {bet.multiplier_no}x
                  </li>
                ) : (
                  ""
                )
              )
            )}
          </ul>
        </div>
        <div
          style={{
            transform: `scale(${`${8 / dataDependent - 0.07}`})`,
            margin: `${-(13 + 19 * -(8 - dataDependent))}px auto`,
            opacity: loading === "loading" ? "50%" : "100%",
          }}
          className="plinko-game-area"
        >
          <div className="plinko-dot text-center">
            {directions.map((directionArr) => (
              <BallMap
                directions={directionArr}
                rows={betValues.row}
                setBinLocation={setBinLocation}
                setMyBets={setMyBets}
                setInprogressBet={setInprogressBet}
                fetchPoolAmounts={fetchPoolAmounts}
                droppingBalls={droppingBalls}
                setDroppingBalls={setDroppingBalls}
              />
            ))}
            {/* <BallMap directions={{}} rows={betValues.row} /> */}

            {pyramid.map((rowCount) => (
              <ul>
                {generateArray(rowCount + 2).map((dot) => (
                  <li></li>
                ))}
              </ul>
            ))}
          </div>
          <ul className="list-style-none plinko-drop-values">
            {bonusLine.length
              ? bonusLine?.map((bonus, index) => (
                  <li
                    className={`color-01 ${
                      binLocation === index + 1 ? "plinko-bin-alocation" : ""
                    }`}
                  >
                    {bonus}x
                  </li>
                ))
              : ""}
          </ul>
        </div>
      </div>
      <ShowComponent condition={plinkoModal}>
        <PlinkoPopup
          game="plinko"
          plinkoModal={plinkoModal}
          setPlinkoModal={setPlinkoModal}
          betId={betId}
          myBets={myBets}
          fetchBetsHistory={fetchBetsHistory}
        />
      </ShowComponent>
    </div>
  );
};

export default Playground;
