import React from "react";
import Cell from "./Cell/Index";
import { useSelector } from "react-redux";

const Board = ({
  grid,
  handleCheckBlock,
  betValues,
  cashOutPopup,
  activatedCurrency,
  setSelectedBoxes,
  selectedBoxes,
  mode,
  setMode,
  handleCheckAuto,
  myClass,
}) => {
  const current_state = useSelector((state) => state.minesBet);
  return (
    <>
      <div className="mines-game-left">
        <p className="mb-3 orbitron-font text-center">Gems</p>

        <p className="fs-20 fw-bold mb-3 orbitron-font text-center">
          {current_state.remaining_gems}
        </p>
        <img src="/assets/mines/svg/gem.svg" className="w-auto" alt="" />
      </div>

      <div className="mines-game-right order-1">
        <p className="mb-3 orbitron-font text-center">Mines</p>
        <p className="fs-20 fw-bold mb-3 orbitron-font text-center">
          {betValues.mines}
        </p>
        <img src="/assets/mines/svg/mine.svg" className="w-auto" alt="" />
      </div>

      <div className="my_row justify mines-game-width">
        {cashOutPopup ? (
          <div className="mines-cashout-popup-containert d-flex ">
            <div className="mines-cashout-popup ">
              <p className="text-success">
                {current_state.current_payout}
                <img
                  src={`/imgs/AcceptedNetworks/${activatedCurrency?.network_id}.webp`}
                  alt=""
                />
              </p>
              <p>{current_state.current_multiplier}X</p>
            </div>
          </div>
        ) : (
          <></>
        )}
        {grid?.map((mineBlock) => {
          return (
            <Cell
            
              key={mineBlock.id}
              mineBlock={mineBlock}
              handleCheckBlock={handleCheckBlock}
              selectedBoxes={selectedBoxes}
              setSelectedBoxes={setSelectedBoxes}
              mode={mode}
              setMode={setMode}
              handleCheckAuto={handleCheckAuto}
              myClass={myClass}
              betValues={betValues}
            />
          );
        })}
      </div>
    </>
  );
};

export default Board;
