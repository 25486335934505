import React, { useState, useEffect, useContext } from "react";
import useSound from "use-sound";

import "./classicdice.css";
import ShareAblePlayArea from "../Shareables/ShareAblePlayArea/ShareAblePlayArea";
import ClassicDiceApis from "../../../api/ClassicDice/ClassicDice";
import { betPriceContext } from "../../Contexts/BetPrice";
import BetStats from "../Limbo/Splitting/Stats/Stats";
import { useDispatch, useSelector } from "react-redux";
import winSound from "./Sound/win.mp3";
import rangeSound from "./Sound/range.mp3";
import moveSound from "./Sound/move.mp3";
import { configuredCurrencyContext } from "../../Contexts/ConfiguredCurrency/ConfiguredCurrency";
import { setUserWalletAmount } from "../../../store/user";
import ShareAbleField from "../Shareables/ShareAbleFields/ShareAbleFIeld";
import ShowComponent from "../../Common/ShowComponent/ShowComponent";
import { useTranslation } from "react-i18next";
import Switch from "../../Common/Header/Wallet/Currencies/Switch/Switch";
import Undermaintenance from "../../Common/Undermaintenance";

export default function ClassicDice() {
  const { t } = useTranslation();
  const { activatedCurrency } = useContext(betPriceContext);
  const { configuredCurrency } = useContext(configuredCurrencyContext);
  const conversion_rates = useSelector((state) => state.game.conversion_rates);
  const user_id = useSelector((state) => state.user.currentUser?.user_id);
  const dispatch = useDispatch();
  const [MoveSound] = useSound(moveSound);
  const [WinSound] = useSound(winSound);
  const [RangeSound] = useSound(rangeSound);

  const inputValues = {
    betAmount: 1,
    userRange: "50",
    roll: "roll under",
    winChance: "50",
    payout: "1.98",
    autoBets: 0,
    increaseOnWin: 0,
    decreaseOnLoss: 0,
    autoProfitStop: 0,
    stopProfit: 0,
    stopLoss: 0,
  };
  const [betValues, setBetValues] = useState(inputValues);
  const [winChance, setWinChance] = useState(50);
  const [multiplierNumber, setMultiplierNumber] = useState(50);
  const [incrementNumber, setIncrementNumber] = useState(null);
  const [decrementNumber, setDecrementNumber] = useState(null);
  const [receivedMultiplier, setReceivedMultiplier] = useState(0);
  const [calculatedAutoLoss, setCalculatedAutoLoss] = useState(0);
  const [autoPlacedBets, setAutoPlacedBets] = useState(0);
  const [autoBetInProgress, setAutoBetInProgress] = useState(false);
  const [calculatedAutoProfit, setCalculatedAutoProfit] = useState(0);
  const [onLossMode, setOnLossMode] = useState("reset");
  const [onWinMode, setOnWinMod] = useState("reset");
  const [betAmountToReset, setBetAmountToReset] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [placed, setPlaced] = useState(false);
  const [myBets, setMyBets] = useState([]);
  const [winStatus, setWinStatus] = useState(false);
  const [inProgressBet, setInprogressBet] = useState(false);
  const [mode, setMode] = useState("manual");
  const [calculatedUsdRate, setCalculatedUsdRate] = useState("");
  const [usdRate, setUsdRate] = useState("");
  const [disabledFields, setDisabled] = useState(false);
  const [showSlider, setShowSlider] = useState(false);
  const addOnButtons = [
    {
      name: "divideBetAmount",
      content: "/2",
      contentType: "text",
    },
    {
      name: "timesBetAmount",
      content: "x2",
      contentType: "text",
    },
    {
      name: "openRangeSlider",
      content: "/assets/limbo/svg/price-slider.svg",
      contentType: "image",
    },
  ];
  const minBetSetter = () => {
    setBetValues({
      ...betValues,
      betAmount: parseFloat(configuredCurrency?.min_bet).toFixed(
        configuredCurrency?.precision
      ),
    });
  };
  const maxBetSetter = () => {
    setBetValues({
      ...betValues,
      betAmount: parseFloat(configuredCurrency?.max_bet).toFixed(
        configuredCurrency?.precision
      ),
    });
  };
  const handleMinBetAmount = () => {
    minBetSetter();
  };
  const handleMaxBetAmount = () => {
    maxBetSetter();
  };
  const multiplyBetAmount = () => {
    if (parseFloat(betValues?.betAmount) * 2 < configuredCurrency?.max_bet) {
      setBetValues({
        ...betValues,
        betAmount: (parseFloat(betValues?.betAmount) * 2).toFixed(
          configuredCurrency?.precision
        ),
      });
    } else {
      maxBetSetter();
    }
  };
  const divideBetAmount = () => {
    if (parseFloat(betValues?.betAmount) / 2 > configuredCurrency?.min_bet) {
      setBetValues({
        ...betValues,
        betAmount: (parseFloat(betValues?.betAmount) / 2).toFixed(
          configuredCurrency?.precision
        ),
      });
    } else {
      minBetSetter();
    }
  };
  const handleDivideAmount = () => {
    divideBetAmount();
  };
  const handleTimesAmount = () => {
    multiplyBetAmount();
  };
  const handleRangeSlider = () => {
    setShowSlider(!showSlider);
  };
  const availableAddOnFunction = {
    divideBetAmount: handleDivideAmount,
    timesBetAmount: handleTimesAmount,
    openRangeSlider: handleRangeSlider,
    minBetAmount: handleMinBetAmount,
    maxBetAmount: handleMaxBetAmount,
  };
  const sliderButtonHandle = (e) => {
    const { name } = e.target;
    availableAddOnFunction[name]();
  };
  const addOnsHandle = (e) => {
    const { name } = e.target;
    availableAddOnFunction[name]();
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    RangeSound();
    setBetValues({ ...betValues, [name]: value });
  };
  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setBetValues({
      ...betValues,
      [name]: value,
    });
  };
  const handleRole = () => {
    setBetValues({
      ...betValues,
      roll: betValues.roll === "roll over" ? "roll under" : "roll over",
      userRange: 100 - betValues.userRange,
    });
  };
  const handleWinChanceChange = (e) => {
    const { value } = e.target;
    setWinChance(value);
  };
  const handleWinChanceBlur = (e) => {
    const { value } = e.target;
    if (!value) {
      setWinChance(parseFloat(betValues.userRange).toFixed(2));
    } else if (parseFloat(value) < 0.01) {
      setWinChance(0.01);
      setBetValues({ ...betValues, userRange: 0.01 });
    } else if (parseFloat(value) > 98) {
      setWinChance((98).toFixed(2));
      setBetValues({ ...betValues, userRange: 98 });
    } else {
      setBetValues({ ...betValues, userRange: value });
    }
  };
  const minSlider = () => {
    setBetValues({
      ...betValues,
      userRange: 0.01,
    });
  };
  const maxSlider = () => {
    setBetValues({
      ...betValues,
      userRange: 98,
    });
  };
  const incrementUserRange = () => {
    if (parseFloat(betValues.userRange + 5) < 98) {
      setBetValues({
        ...betValues,
        userRange: betValues.userRange + 5,
      });
    } else {
      setBetValues({
        ...betValues,
        userRange: 98,
      });
    }
  };
  const decrementUserRange = () => {
    if (parseFloat(betValues.userRange - 5) > 2) {
      setBetValues({
        ...betValues,
        userRange: betValues.userRange - 5,
      });
    } else {
      setBetValues({
        ...betValues,
        userRange: 2,
      });
    }
  };
  const availableFunctions = {
    increment: incrementUserRange,
    decrement: decrementUserRange,
    minRange: minSlider,
    maxRange: maxSlider,
  };
  const handleArithmetic = (e) => {
    const { name } = e.target;
    availableFunctions[name]();
  };
  const fetchMyBets = () => {
    ClassicDiceApis.diceBets()
      .then((res) => {
        setIsLoading(false);
        setMyBets(res.data.user_bets);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };
  const placeBet = () => {
    setInprogressBet(true);
    ClassicDiceApis.place_bet({
      dice: {
        amount: parseFloat(betValues.betAmount),
        starting_point:
          betValues.roll === "roll under" ? 0 : parseFloat(betValues.userRange),
        ending_point:
          betValues.roll === "roll under"
            ? parseFloat(betValues.userRange)
            : 100,
        network_id: activatedCurrency.network_id,
        cashout: parseFloat(betValues.payout),
      },
    })
      .then((res) => {
        setPlaced(true);
        if (res.data.data.multiplier_no > parseFloat(multiplierNumber)) {
          setIncrementNumber(parseFloat(res.data.data.multiplier_no) / 8);
        }
        if (res.data.data.multiplier_no < parseFloat(multiplierNumber)) {
          setDecrementNumber(
            (parseFloat(multiplierNumber) - res.data.data.multiplier_no) / 8
          );
        }
        setReceivedMultiplier(res.data.data.multiplier_no);
        if (res.data.data.win_status) {
          if (autoBetInProgress) {
            setCalculatedAutoProfit(
              calculatedAutoProfit + parseFloat(res.data.data.profit)
            );
            let percentage =
              (parseFloat(betValues.betAmount) / 100) *
              parseFloat(betValues.increaseOnWin);
            if (calculatedAutoLoss - parseFloat(res.data.data.profit) > 0) {
              setCalculatedAutoLoss(
                calculatedAutoLoss - parseFloat(res.data.data.profit)
              );
            } else {
              setCalculatedAutoLoss(0);
            }
            if (onWinMode === "increase") {
              if (
                parseFloat(betValues.betAmount) + parseFloat(percentage) >
                parseFloat(configuredCurrency.max_bet)
              ) {
                setBetValues({
                  ...betValues,
                  betAmount: parseFloat(configuredCurrency.max_bet).toFixed(
                    configuredCurrency?.precision
                  ),
                });
              } else {
                setBetValues({
                  ...betValues,
                  betAmount: (
                    parseFloat(betValues.betAmount) + percentage
                  ).toFixed(configuredCurrency?.precision),
                });
              }
            } else {
              setBetValues({
                ...betValues,
                betAmount: parseFloat(betAmountToReset).toFixed(
                  configuredCurrency?.precision
                ),
              });

              // alert("on win mod set to reset");
            }
          }
        } else {
          if(autoBetInProgress){
            let percentage =
              (parseFloat(betValues.betAmount) / 100) *
              parseFloat(betValues.decreaseOnLoss);

              setCalculatedAutoLoss(
                calculatedAutoLoss + parseFloat(res.data.data.profit)
              );
              if (calculatedAutoProfit - parseFloat(res.data.data.profit) > 0) {
                setCalculatedAutoProfit(
                  calculatedAutoProfit - parseFloat(res.data.data.profit)
                );
              } else {
                setCalculatedAutoProfit(0);
              }
              if (onLossMode === "increase") {
                if (
                  parseFloat(betValues.betAmount) + parseFloat(percentage) >
                  parseFloat(configuredCurrency.max_bet)
                ) {
                  setBetValues({
                    ...betValues,
                    betAmount: parseFloat(configuredCurrency.max_bet).toFixed(
                      configuredCurrency?.precision
                    ),
                  });
                } else {
                  setBetValues({
                    ...betValues,
                    betAmount: (
                      parseFloat(betValues.betAmount) + percentage
                    ).toFixed(configuredCurrency?.precision),
                  });
                }
              } else {
                setBetValues({
                  ...betValues,
                  betAmount: parseFloat(betAmountToReset).toFixed(
                    configuredCurrency?.precision
                  ),
                });
    
                // alert("on win mod set to reset");
              }
          }

          
        }
        setTimeout(() => {
          setWinStatus(res.data.data.win_status);

          if (res.data.data.win_status === true) {
            WinSound();
          }

          dispatch(
            setUserWalletAmount({
              network_id: res.data.data.network_id,
              amount: res.data.data.wallet_amount,
            })
          );
          fetchMyBets();
          setAutoPlacedBets(autoPlacedBets + 1);
          setInprogressBet(false);
        }, 800);
      })
      .catch((err) => {
        setAutoBetInProgress(false);
      });
  };
  const handleAutoBet = () => {
    setBetAmountToReset(betValues.betAmount);
    setAutoBetInProgress(!autoBetInProgress);
  };
  const autoBetsOnChange = (e) => {
    const { value } = e.target;
    setBetValues({
      ...betValues,
      autoBets: parseInt(value),
    });
    if (autoPlacedBets) {
      setAutoPlacedBets(0);
    }
  };
  const autoBetsOnBlur = (e) => {
    const { value } = e.target;
    if (!value) {
      setBetValues({
        ...betValues,
        autoBets: 10,
      });
    }
    if (autoPlacedBets) {
      setAutoPlacedBets(0);
    }
  };
  const handleBetAmountBlur = (e) => {
    const { name, value } = e.target;
    if (!value || parseFloat(value) < 0) {
      minBetSetter();
    } else if (parseFloat(value) < configuredCurrency?.min_bet) {
      minBetSetter();
    } else if (parseFloat(value) > configuredCurrency?.max_bet) {
      maxBetSetter();
    } else {
      setBetValues({
        ...betValues,
        [name]: parseFloat(value).toFixed(configuredCurrency?.precision),
      });
    }
  };
  const stopLossProfitBlur = (e) => {
    const { name, value } = e.target;
    if (!value || parseFloat(value) < 0) {
      setBetValues({
        ...betValues,
        [name]: parseFloat(0).toFixed(configuredCurrency?.precision),
      });
    } else {
      setBetValues({
        ...betValues,
        [name]: parseFloat(value).toFixed(configuredCurrency?.precision),
      });
    }
  };
  const handleAutoBetsNumber = (e) => {
    const { name } = e.target;

    setBetValues({
      ...betValues,
      autoBets: parseInt(name),
    });
    setAutoPlacedBets(0);
  };
  const stopLossBlurHandle = (e) => {
    if (!e.target.value || parseFloat(e.target.value) < 0) {
      setBetValues({
        ...betValues,
        [e.target.name]: 0,
      });
    }
  };
  const handleOnWinMode = () => {
    if (onWinMode === "reset") {
      setOnWinMod("increase");
    } else {
      setOnWinMod("reset");
    }
  };
  const handleOnLossMode = () => {
    if (onLossMode === "reset") {
      setOnLossMode("increase");
    } else {
      setOnLossMode("reset");
    }
  };
  useEffect(() => {
    if (!placed) {
      return;
    }
    if (parseFloat(receivedMultiplier) > parseFloat(multiplierNumber)) {
      if (
        parseFloat(multiplierNumber) + parseFloat(incrementNumber) <
        parseFloat(receivedMultiplier)
      ) {
        const interval = setTimeout(() => {
          MoveSound();
          setMultiplierNumber(
            parseFloat(multiplierNumber) + parseFloat(incrementNumber)
          );
        }, 80);
      } else {
        setMultiplierNumber(receivedMultiplier);
      }
    }
  }, [incrementNumber, multiplierNumber]);
  useEffect(() => {
    if (!placed) {
      return;
    }
    if (parseFloat(receivedMultiplier) < parseFloat(multiplierNumber)) {
      if (
        parseFloat(multiplierNumber) - parseFloat(decrementNumber) >
        parseFloat(receivedMultiplier)
      ) {
        const interval = setTimeout(() => {
          MoveSound();
          setMultiplierNumber(
            parseFloat(multiplierNumber) - parseFloat(decrementNumber)
          );
        }, 80);
      } else {
        setMultiplierNumber(receivedMultiplier);
      }
    }
  }, [decrementNumber, multiplierNumber]);
  useEffect(() => {
    if (betValues.roll === "roll under") {
      setWinChance(parseFloat(betValues.userRange).toFixed(2));
    } else {
      setWinChance(parseFloat(100 - betValues.userRange).toFixed(2));
    }
  }, [betValues.userRange, betValues.roll]);
  useEffect(() => {
    setBetValues({
      ...betValues,
      payout: ((100 - 1) / winChance).toFixed(4),
    });
  }, [winChance]);
  useEffect(() => {
    if (!user_id) {
      setMyBets([]);
    } else {
      fetchMyBets();
    }
  }, [user_id]);
  useEffect(() => {
    if (parseFloat(betValues.stopProfit) !== 0) {
      if (
        parseFloat(calculatedAutoProfit) >= parseFloat(betValues.stopProfit)
      ) {
        setAutoBetInProgress(false);
        setCalculatedAutoProfit(0);
      }
    }
    if (parseFloat(betValues.stopLoss) !== 0) {
      if (parseFloat(calculatedAutoLoss) >= parseFloat(betValues.stopLoss)) {
        setAutoBetInProgress(false);
        setCalculatedAutoLoss(0);
      }
    }
  }, [calculatedAutoProfit, calculatedAutoLoss]);
  useEffect(() => {
    if (
      parseInt(autoPlacedBets) < parseInt(betValues.autoBets) &&
      autoBetInProgress &&
      parseInt(betValues.autoBets) - autoPlacedBets > 0
    ) {
      placeBet();
    } else if (autoBetInProgress && parseInt(betValues.autoBets) == 0) {
      placeBet();
    } else {
      setAutoBetInProgress(false);
      setAutoPlacedBets(0);
    }
  }, [autoPlacedBets, autoBetInProgress]);
  useEffect(() => {
    setUsdRate(
      conversion_rates.conversion_rates.find(
        (rate) =>
          rate.from_currency === activatedCurrency.network_id &&
          rate.to_currency === "united_states_dollar"
      )
    );
  }, [activatedCurrency.network_id]);
  useEffect(() => {
    setCalculatedUsdRate(
      (parseFloat(usdRate?.rate) * parseFloat(betValues.betAmount)).toFixed(4)
    );
  }, [betValues.betAmount, usdRate?.rate]);

  useEffect(() => {
    minBetSetter();
  }, [activatedCurrency.network_id, configuredCurrency]);
  return (
    <div className="main-wrapper-container">
      <div className="row m-0">
        <div className="col-lg-9 mb-4 p-0 dark-bg-four">
          <div className="classicdice-game p-3">
            <div className="align-content-center d-flex justify-content-start position-relative z-index-1">
              <ul className="align-items-center d-flex flex-fill justify-content-end games-trends-bar gap-2 orbitron-font fs-14">
                {!myBets.length ? (
                  <li className="bold dark-bg-one">BET HISTORY</li>
                ) : (
                  myBets.map(
                    (bet, index) =>
                      index < 8 && (
                        <li
                          className={`bold ${
                            bet.win_status ? `bg-success` : `dark-bg-one`
                          }`}
                        >
                          {bet.multiplier_no}
                        </li>
                      )
                  )
                )}
              </ul>
            </div>
            <div className="classicdice-container">
              <div className="classicdice-num">
                <span
                  style={{
                    left: `${multiplierNumber}%`,
                    transition: ".2s",
                  }}
                >
                  {multiplierNumber.toFixed(2)}
                </span>
              </div>
              <div className="classicdice-bg-spin-main-box mb-5 mt-2">
                <div
                  style={{
                    left: `${multiplierNumber}%`,
                    transition: ".2s",
                  }}
                  className={`text-center classicdice-bg-spin ${
                    winStatus && "classicdice-bg-spiner"
                  } `}
                >
                  <img
                    src="/assets/classic-dice/dice-icon.png"
                    className="w-auto"
                    alt=""
                  />
                </div>
              </div>
              <div className="classicdice-slider position-relative">
                <div
                  style={{
                    flexDirection:
                      betValues.roll === "roll under" ? "row" : "row-reverse",
                  }}
                  className="classicdice-range-slider-bar"
                >
                  <div
                    style={{
                      left: `${betValues.userRange}%`,
                    }}
                    className="classicdice-num-tool-tip absolute"
                  >
                    <span>{betValues.userRange}</span>
                  </div>
                  <div
                    style={{
                      borderRadius: "20px 0 0 20px",
                      background: "var(--text-color-blue)",
                      width: `${
                        betValues.roll === "roll under"
                          ? betValues.userRange
                          : 100 - parseFloat(betValues.userRange)
                      }%`,
                    }}
                  ></div>
                  <div
                    className="bg-danger"
                    style={{
                      borderRadius: "0 20px 20px 0",
                      width: `${
                        betValues.roll === "roll under"
                          ? 100 - parseFloat(betValues.userRange)
                          : betValues.userRange
                      }%`,
                    }}
                  ></div>
                </div>

                <input
                  name="userRange"
                  type="range"
                  className="classicdice-form-range"
                  min="2"
                  max="98"
                  step={1}
                  value={betValues.userRange}
                  onChange={handleInputChange}
                  disabled={inProgressBet}
                />
              </div>
              <ul className="classicdice-percentage-digit d-flex list-style-none justify-content-between mb-5 fs-14 secondary_text">
                <li>0</li>
                <li>25</li>
                <li>50</li>
                <li>75</li>
                <li>100</li>
              </ul>
              <div className="classicdice-form dark-bg-two p-3 pb-0 radius-4">
                <div className="row">
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <p className="fs-13 secondary_text mb-2">Payout</p>
                        <input
                          type="text"
                          className="form-control"
                          value={betValues.payout}
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <p className="fs-13 secondary_text mb-2">
                          {betValues.roll}
                        </p>

                        <div className="position-relative" onClick={handleRole}>
                          <div className="d-flex end-0 me-3 position-absolute top-50 translate-middle-y">
                            <img
                              src="/assets/classic-dice/roll-under.png"
                              className="w-auto"
                              alt=""
                            />
                          </div>
                          <input
                            type="text"
                            className="form-control pointer"
                            value={betValues.userRange}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <p className="fs-13 secondary_text mb-2">Win Chance</p>
                    <div className="position-relative">
                      <input
                        name="winChance"
                        type="text"
                        className="form-control"
                        value={winChance}
                        onChange={handleWinChanceChange}
                        onBlur={handleWinChanceBlur}
                      />
                      <div className="d-flex end-0 me-2 position-absolute top-50 translate-middle-y">
                        <p className="blue_color p-2">%</p>
                        <button
                          className="dark-bg-one ms-1 p-2 radius-3 secondary_text cursor-pointer"
                          onClick={handleArithmetic}
                          name="minRange"
                        >
                          Min
                        </button>
                        <button
                          onClick={handleArithmetic}
                          name="decrement"
                          className="dark-bg-one ms-1 p-2 radius-3 secondary_text cursor-pointer"
                        >
                          -5
                        </button>
                        <button
                          onClick={handleArithmetic}
                          name="increment"
                          className="dark-bg-one ms-1 p-2 radius-3 secondary_text cursor-pointer"
                        >
                          +5
                        </button>
                        <button
                          className="dark-bg-one ms-1 p-2 radius-3 secondary_text cursor-pointer"
                          onClick={handleArithmetic}
                          name="maxRange"
                        >
                          Max
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3 mb-4 p-0 dark-bg-four order-lg-first">
          <div className="p-3 game-play-area">
            <ShareAblePlayArea
              modsAvaiable={true}
              mode={mode}
              setMode={setMode}
            >
              <ShareAbleField
                value={betValues.betAmount}
                setBetValues={setBetValues}
                labelTwoActive={true}
                secondLabel="USD"
                secondLabelValue={
                  activatedCurrency.network_id === "wb"
                    ? "0"
                    : !calculatedUsdRate || calculatedUsdRate === "NaN"
                    ? "..."
                    : calculatedUsdRate
                }
                label="Amount"
                currencyIcon={true}
                rangeSlider={true}
                showSlider={showSlider}
                name="betAmount"
                type="number"
                placeholder="Bet Amount"
                handleOnChange={handleOnChange}
                addOns={addOnButtons}
                addOnsHandle={addOnsHandle}
                onBlur={handleBetAmountBlur}
                disabled={inProgressBet}
                sliderButtonHandle={sliderButtonHandle}
                min={parseFloat(configuredCurrency?.min_bet).toFixed(
                  configuredCurrency?.precision
                )}
                max={parseFloat(configuredCurrency?.max_bet).toFixed(
                  configuredCurrency?.precision
                )}
              />
            </ShareAblePlayArea>
            <ShowComponent condition={mode === "manual"}>
              <ShareAbleField
                value={betValues.betAmount * betValues.payout}
                setBetValues={setBetValues}
                showUsdRate={false}
                label="Win Amount"
                currencyIcon={false}
                rangeSlider={false}
                name="winAmount"
                type="number"
                placeholder="Win Amount"
                handleOnChange={handleOnChange}
                addOns={[]}
                disabled={inProgressBet}
              />
            </ShowComponent>
            <ShowComponent condition={mode === "manual"}>
              <button
                className="orbitron-font primary-btn primary-btn-bg bet-button-bubbles w-100"
                onClick={placeBet}
              >
                Roll Now
              </button>
            </ShowComponent>
            <ShowComponent condition={mode === "auto"}>
              <div
                style={{
                  marginBottom: "1.5rem",
                }}
                className=""
              >
                <div className="d-flex justify-content-between mb-2 fs-14 secondary_text">
                  <p className="mb-0">{t("gamebox.betit.betnumber")}: &nbsp;</p>
                </div>
                <div className="position-relative">
                  <div className="d-flex end-0 me-2 position-absolute top-50 translate-middle-y">
                    <button
                      name="0"
                      className="dark-bg-one ms-1 p-2 radius-3 secondary_text cursor-pointer"
                      onClick={handleAutoBetsNumber}
                    >
                      &infin;
                    </button>
                    <button
                      name="10"
                      className="dark-bg-one ms-1 p-2 radius-3 secondary_text cursor-pointer"
                      onClick={handleAutoBetsNumber}
                    >
                      10
                    </button>
                    <button
                      name="100"
                      className="cursor-pointer dark-bg-one ms-1 p-2 radius-3 secondary_text range-slider-icon"
                      onClick={handleAutoBetsNumber}
                    >
                      100
                    </button>
                  </div>
                  <input
                    type="number"
                    name="betAmount"
                    className={`${
                      inProgressBet ? "form-control-disabled" : "form-control"
                    }`}
                    placeholder={t("gamebox.betit.autobets")}
                    value={
                      betValues.autoBets == 0
                        ? 0
                        : betValues.autoBets - autoPlacedBets
                    }
                    onChange={autoBetsOnChange}
                    onBlur={autoBetsOnBlur}
                    disabled={disabledFields}
                  />
                </div>
              </div>
            </ShowComponent>
            <ShowComponent condition={mode === "auto"}>
              <div className="mt-4 mb-4">
                <div className="">
                  <div className="d-flex justify-content-between mb-2 fs-14 secondary_text">
                    <p className="mb-0">{t("gamebox.betit.onwin")}</p>
                  </div>
                  <div className="position-relative justified">
                    <button
                      className="width-50 justified"
                      onClick={handleOnWinMode}
                    >
                      <div
                        style={{
                          transform: "rotate(90deg)",
                        }}
                        className="switch-capsule"
                      >
                        <Switch
                          activeStatus={onWinMode === "increase" ? true : false}
                          onClickHandle={handleOnWinMode}
                        />
                      </div>
                      <div className="typo-graphy-container my_row column">
                        <div className="reset-text">
                          <span className="secondary_text">
                            {t("gamebox.betit.reset")}
                          </span>
                        </div>
                        <div className="increase-text">
                          <span
                            className={`${
                              onWinMode === "increase"
                                ? "secondary_text"
                                : "white"
                            }`}
                          >
                            {t("gamebox.betit.increaseby")}
                          </span>
                        </div>
                      </div>
                    </button>

                    <input
                      type="number"
                      name="increaseOnWin"
                      style={{
                        width: "50%",
                      }}
                      className={`${
                        inProgressBet ? "form-control-disabled" : "form-control"
                      }`}
                      placeholder={t("gamebox.betit.increasewin")}
                      value={betValues.increaseOnWin}
                      onChange={handleOnChange}
                      onBlur={stopLossBlurHandle}
                      disabled={disabledFields}
                    />
                  </div>
                </div>
              </div>
            </ShowComponent>
            <ShowComponent condition={mode === "auto"}>
              <div className="mt-4 mb-4">
                <div className="">
                  <div className="d-flex justify-content-between mb-2 fs-14 secondary_text">
                    <p className="mb-0">{t("gamebox.betit.stopprofit")}: </p>
                    <p>
                      {t("gamebox.betit.currentprofit")}:{" "}
                      {parseFloat(calculatedAutoProfit).toFixed(
                        configuredCurrency?.precision
                      )}
                    </p>
                  </div>
                  <div className="position-relative">
                    <img
                      src={`/imgs/AcceptedNetworks/${activatedCurrency?.network_id}.webp`}
                      alt={activatedCurrency?.network_id}
                      className="ms-3 position-absolute currency-icons top-50 translate-middle-y"
                    />
                    <input
                      type="number"
                      name="stopProfit"
                      className={`${
                        inProgressBet ? "form-control-disabled" : "form-control"
                      } ps-5`}
                      placeholder={t("gamebox.betit.stopprofit")}
                      value={betValues.stopProfit}
                      onChange={handleOnChange}
                      onBlur={stopLossProfitBlur}
                      disabled={disabledFields}
                    />
                  </div>
                </div>
              </div>
            </ShowComponent>
            <ShowComponent condition={mode === "auto"}>
              <div className="mt-4 mb-4">
                <div className="">
                  <div className="d-flex justify-content-between mb-2 fs-14 secondary_text">
                    <p className="mb-0">{t("gamebox.betit.onloss")}</p>
                  </div>
                  <div className="position-relative justified">
                    <button
                      className="width-50 justified"
                      onClick={handleOnLossMode}
                    >
                      <div
                        style={{
                          transform: "rotate(90deg)",
                        }}
                        className="switch-capsule"
                      >
                        <Switch
                          activeStatus={
                            onLossMode === "increase" ? true : false
                          }
                          onClickHandle={handleOnLossMode}
                        />
                      </div>
                      <div className="typo-graphy-container my_row column">
                        <div className="reset-text">
                          <span className="secondary_text">
                            {t("gamebox.betit.reset")}
                          </span>
                        </div>
                        <div className="increase-text">
                          <span
                            className={`${
                              onLossMode === "increase"
                                ? "secondary_text"
                                : "white"
                            }`}
                          >
                            {t("gamebox.betit.increaseby")}
                          </span>
                        </div>
                      </div>
                    </button>
                    <input
                      type="number"
                      name="decreaseOnLoss"
                      style={{
                        width: "50%",
                      }}
                      className={`${
                        inProgressBet ? "form-control-disabled" : "form-control"
                      }`}
                      placeholder={t("gamebox.betit.increaseloss")}
                      value={betValues.decreaseOnLoss}
                      onChange={handleOnChange}
                      onBlur={stopLossBlurHandle}
                      disabled={disabledFields}
                    />
                  </div>
                </div>
              </div>
            </ShowComponent>
            <ShowComponent condition={mode === "auto"}>
              <div className="mt-4 mb-4">
                <div className="">
                  <div className="d-flex justify-content-between mb-2 fs-14 secondary_text">
                    <p className="mb-0">{t("gamebox.betit.stoploss")}</p>
                    <p className="mb-0">
                      {t("gamebox.betit.currentloss")}:{" "}
                      {parseFloat(calculatedAutoLoss).toFixed(
                        configuredCurrency?.precision
                      )}
                    </p>
                  </div>
                  <div className="position-relative">
                    <img
                      src={`/imgs/AcceptedNetworks/${activatedCurrency?.network_id}.webp`}
                      alt={activatedCurrency?.network_id}
                      className="ms-3 position-absolute currency-icons top-50 translate-middle-y"
                    />
                    <input
                      type="number"
                      name="stopLoss"
                      className={`${
                        inProgressBet ? "form-control-disabled" : "form-control"
                      } ps-5`}
                      placeholder={t("gamebox.betit.stoploss")}
                      value={betValues.stopLoss}
                      onChange={handleOnChange}
                      onBlur={stopLossProfitBlur}
                      disabled={disabledFields}
                    />
                  </div>
                </div>
              </div>
            </ShowComponent>
            <ShowComponent condition={mode === "auto"}>
              <div className="">
                {autoBetInProgress ? (
                  <button
                    className="orbitron-font primary-btn primary-btn-bg bet-button-bubbles w-100"
                    onClick={handleAutoBet}
                  >
                    {t("gamebox.betit.stopbet")}
                  </button>
                ) : (
                  <button
                    className="orbitron-font primary-btn primary-btn-bg bet-button-bubbles w-100"
                    onClick={handleAutoBet}
                  >
                    {t("gamebox.betit.startbet")}
                  </button>
                )}
              </div>
            </ShowComponent>
          </div>
        </div>
      </div>
      <div className="crashGame-history-container">
        <BetStats myBets={myBets} isLoading={isLoading} placeBet={placeBet} />
      </div>
    </div>
  );
  return (
    <Undermaintenance
      type="soon"
      time=""
      support={false}
      p1="For our users to experience wonderful playtime,"
      p2="we are about to release new games. Stay tuned!"
    />
  );
}
