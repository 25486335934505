import React, { useEffect, useContext, useState } from "react";
import Manual from "./Manual/Manual";
import KenoApis from "../../../../api/Keno/keno";
import ShareAbleField from "../../Shareables/ShareAbleFields/ShareAbleFIeld";
import { configuredCurrencyContext } from "../../../Contexts/ConfiguredCurrency/ConfiguredCurrency";
import { betPriceContext } from "../../../Contexts/BetPrice";
import { useSelector, useDispatch } from "react-redux";
import { setUserWallets } from "../../../../store/user";
import UserWalletsAPI from "../../../../api/user_wallets";

const MutualMods = ({
  setKenoBoxes,
  generateArray,
  selectedBoxes,
  setSelectedBoxes,
  fetchMyBets,
  setBetValues,
  betValues,
  setServerMatchedLength,
  setResultsFetched,
}) => {
  const dispatch = useDispatch();
  const [usdRate, setUsdRate] = useState("");
  const conversion_rates = useSelector((state) => state.game.conversion_rates);
  const { activatedCurrency } = useContext(betPriceContext);
  const { configuredCurrency } = useContext(configuredCurrencyContext);
  const [showSlider, setShowSlider] = useState(false);
  const [calculatedUsdRate, setCalculatedUsdRate] = useState("");
  const [inProgressBet, setInprogressBet] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const sleep = (ms) => new Promise((r) => setTimeout(r, ms));


  const generateResultHash = (serverResults) => {
    let prevItems = [];
    for (let i = 0; i < serverResults.length; i++) {
      let updatedItem = serverResults[i];
      prevItems[i] = updatedItem;
      sleep(100);

    }
    setKenoBoxes(prevItems);
  };
  const clearTable = () => {
    setKenoBoxes(generateArray(40));
    setSelectedBoxes([]);
    setResultsFetched(false);
  };
  const resetTable = () => {
    setKenoBoxes(generateArray(40));
    setResultsFetched(false);
  };
  const addOnButtons = [
    {
      name: "divideBetAmount",
      content: "/2",
      contentType: "text",
    },
    {
      name: "timesBetAmount",
      content: "x2",
      contentType: "text",
    },
    {
      name: "openRangeSlider",
      content: "/assets/limbo/svg/price-slider.svg",
      contentType: "image",
    },
  ];
  const minBetSetter = () => {
    setBetValues({
      ...betValues,
      betAmount: parseFloat(configuredCurrency?.min_bet).toFixed(
        configuredCurrency?.precision
      ),
    });
  };
  const maxBetSetter = () => {
    setBetValues({
      ...betValues,
      betAmount: parseFloat(configuredCurrency?.max_bet).toFixed(
        configuredCurrency?.precision
      ),
    });
  };
  const handleMinBetAmount = () => {
    minBetSetter();
  };
  const handleMaxBetAmount = () => {
    maxBetSetter();
  };
  const multiplyBetAmount = () => {
    if (parseFloat(betValues?.betAmount) * 2 < configuredCurrency?.max_bet) {
      setBetValues({
        ...betValues,
        betAmount: (parseFloat(betValues?.betAmount) * 2).toFixed(
          configuredCurrency?.precision
        ),
      });
    } else {
      maxBetSetter();
    }
  };
  const divideBetAmount = () => {
    if (parseFloat(betValues?.betAmount) / 2 > configuredCurrency?.min_bet) {
      setBetValues({
        ...betValues,
        betAmount: (parseFloat(betValues?.betAmount) / 2).toFixed(
          configuredCurrency?.precision
        ),
      });
    } else {
      minBetSetter();
    }
  };
  const handleDivideAmount = () => {
    divideBetAmount();
  };
  const handleTimesAmount = () => {
    multiplyBetAmount();
  };
  const handleRangeSlider = () => {
    setShowSlider(!showSlider);
  };
  const availableAddOnFunction = {
    divideBetAmount: handleDivideAmount,
    timesBetAmount: handleTimesAmount,
    openRangeSlider: handleRangeSlider,
    minBetAmount: handleMinBetAmount,
    maxBetAmount: handleMaxBetAmount,
  };
  const sliderButtonHandle = (e) => {
    const { name } = e.target;
    availableAddOnFunction[name]();
  };
  const addOnsHandle = (e) => {
    const { name } = e.target;
    availableAddOnFunction[name]();
  };
  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setBetValues({
      ...betValues,
      [name]: value,
    });
  };
  const handleBetAmountBlur = (e) => {
    const { name, value } = e.target;
    if (!value || parseFloat(value) < 0) {
      minBetSetter();
    } else if (parseFloat(value) < configuredCurrency?.min_bet) {
      minBetSetter();
    } else if (parseFloat(value) > configuredCurrency?.max_bet) {
      maxBetSetter();
    } else {
      setBetValues({
        ...betValues,
        [name]: parseFloat(value).toFixed(configuredCurrency?.precision),
      });
    }
  };
  const createBet = () => {
    if (inProgressBet) {
      return;
    }
    resetTable();
    setInprogressBet(true);
    KenoApis.create_bet({
      keno: {
        amount: parseFloat(betValues.betAmount),
        network_id: activatedCurrency.network_id,
        risk: parseInt(betValues.risk),
        client_selected_tiles: selectedBoxes,
      },
    })
      .then((res) => {
        setInprogressBet(false);
        generateResultHash(res.data.data.selection_results);
        setServerMatchedLength(
          res.data.data.selection_results.filter(
            (selection) => selection.type === "server_matched"
          ).length
        );
        setResultsFetched(true);
        fetchMyBets();

        UserWalletsAPI.getUserWallets()
          .then((response) => {
            dispatch(
              setUserWallets({
                wallets: response.data,
              })
            );
          })
          .catch((error) => {
            console.log(error);
          });

        // dispatch(
        //   setUserWalletAmount({
        //     network_id: res.data.data.network_id,
        //     amount: res.data.data.wallet_amount,
        //   })
        // );
      })
      .catch((err) => {
        setInprogressBet(false);
        setAlertMessage(err?.response?.data?.message);
        setTimeout(() => {
          setAlertMessage("");
        }, 3000);
      });
  };

  useEffect(() => {
    setUsdRate(
      conversion_rates.conversion_rates.find(
        (rate) =>
          rate.from_currency === activatedCurrency.network_id &&
          rate.to_currency === "united_states_dollar"
      )
    );
  }, [activatedCurrency.network_id]);
  useEffect(() => {
    setCalculatedUsdRate(
      (parseFloat(usdRate?.rate) * parseFloat(betValues.betAmount)).toFixed(4)
    );
  }, [betValues.betAmount, usdRate?.rate]);

  useEffect(() => {
    minBetSetter();
  }, [activatedCurrency.network_id, configuredCurrency]);

  return (
    <>
      <ShareAbleField
        value={betValues?.betAmount}
        setBetValues={setBetValues}
        labelTwoActive={true}
        secondLabel="USD"
        secondLabelValue={
          activatedCurrency.network_id === "wb"
            ? "0"
            : !calculatedUsdRate || calculatedUsdRate === "NaN"
            ? "..."
            : calculatedUsdRate
        }
        label="Amount"
        currencyIcon={true}
        rangeSlider={true}
        showSlider={showSlider}
        name="betAmount"
        type="number"
        placeholder="Bet Amount"
        handleOnChange={handleOnChange}
        addOns={addOnButtons}
        addOnsHandle={addOnsHandle}
        onBlur={handleBetAmountBlur}
        disabled={inProgressBet}
        sliderButtonHandle={sliderButtonHandle}
        min={parseFloat(configuredCurrency?.min_bet).toFixed(
          configuredCurrency?.precision
        )}
        max={parseFloat(configuredCurrency?.max_bet).toFixed(
          configuredCurrency?.precision
        )}
      />
      <Manual
        alertMessage={alertMessage}
        data={{
          inProgressBet: inProgressBet,
          betValues: betValues,
          createBet: createBet,
          clearTable: clearTable,
          handleOnChange: handleOnChange,
        }}
      />
    </>
  );
};

export default MutualMods;
