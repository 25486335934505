import React, { useState, useEffect } from "react";
import { Modal, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ShowComponent from "../../../Common/ShowComponent/ShowComponent";
import VerificationDescisionModal from "./VerifyDescision";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { useTranslation, Trans } from "react-i18next";
// Redux Imports
import { useSelector } from "react-redux";
// APi Imports
import LimboAPis from "../../../../api/Limbo/limbo";

const LimboPopup = ({
  setLimboModal,
  myBets,
  betId,
  fetchBetsHistory,
  game,
}) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "475px",
    height: "550px !important",
    bgcolor: "#0e0f10",
    boxShadow: 24,
    p: 4,
    borderRadius: "20px",
    color: "#ccc",
    padding: 0,
    overflowY: "auto",
    overflowX: "hidden !important",
    // "&::-webkit-scrollbar": { display: "none" },
  };

  // Redux States
  const user_profile = useSelector((state) => state.user.user_profile);
  // Controlled States
  const [betData, setBetData] = useState({});
  const [verifyDescision, setVirificationDescision] = useState(false);
  const [newSeed, setNewSeed] = useState(false);
  const [changeAbleSeed, setChangeAbleSeed] = useState("");
  const [gettingNewSeed, setGettingNewSeed] = useState(false);
  // Helper Functions
  const { t } = useTranslation();
  const handleClose = () => {
    setLimboModal(false);
  };
  const handleVerify = () => {
    if (!betData.exposed) {
      setVirificationDescision(true);
    } else {
      let a = document.createElement("a");
      a.target = "_blank";
      a.href = `https://hash-verification.web.app/limbo/?clientSeed=${betData.client_seed}&severSeed=${betData.server_seed}&nonce=${betData.nonce}&serverSeedhashed=${betData.server_seed_hashed}`;
      a.click();
    }
  };
  const handleCliendSeed = (e) => {
    const { value } = e.target;
    setChangeAbleSeed(value);
  };
  // Fetch Functions
  const fetchNewSeed = () => {
    LimboAPis.get_client_seed()
      .then((res) => {
        setChangeAbleSeed(res.data.data.client_seed);
      })
      .catch((err) => console.log(err));
  };
  const handleUseNewSeed = () => {
    setGettingNewSeed(true);
    LimboAPis.get_new_seed(
      {
        [game]: {
          client_seed: changeAbleSeed,
        },
      },
      game
    )
      .then((res) => {
        fetchBetsHistory();
        setGettingNewSeed(false);
        setNewSeed(false);
        setLimboModal(false);
      })
      .catch((err) => {
        setGettingNewSeed(false);
        setNewSeed(false);
        setLimboModal(false);
      });
  };
  // Effects
  useEffect(() => {
    fetchNewSeed();
  }, []);
  useEffect(() => {
    setBetData(myBets.find((bet) => bet.id == betId));
    setChangeAbleSeed(myBets.find((bet) => bet.id == betId).client_seed);
  }, []);

  return (
    <>
      <Modal
        open={true}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          className="all-player-popup modals-height-on-mobiles main-wrapper-container"
        >
          <div className="bet-user-modal-inner">
            <ShowComponent condition={verifyDescision}>
              <VerificationDescisionModal
                setVirificationDescision={setVirificationDescision}
                setNewSeed={setNewSeed}
              />
            </ShowComponent>

            <div
              style={{
                padding: "20px",
              }}
              className="bet-user-modal-top my_row space-between"
            >
 
              <span className="orbitron-font">{t("header.verificationbets.BetDetails")}</span>
 
              <span>
                <button className="white" onClick={handleClose}>
                  <CloseIcon />
                </button>
              </span>
            </div>

            <div className="modal-body p-4">
              <ShowComponent condition={!newSeed}>
                <div className="align-items-center d-flex justify-content-center gap-3 mb-3">
                  <img
                    src="/assets/limbo/svg/bet-green-tick.svg"
                    alt=""
                    className="w-auto"
                  />
 
                  <p className="mb-0 text-white">{t("stats.mybets.betid")}: {betId} </p>
 
                  <a href="javascript:;">
                    <img
                      src="/assets/limbo/svg/copy-icon.svg"
                      alt=""
                      className="w-auto"
                    />
                  </a>
                </div>

                <div className="mb-3 text-center">
 
                  {t("header.verificationbets.By")}
                  <span className="text-white mx-3">
                    {user_profile?.username}
                  </span>
                  {t("header.verificationbets.On")}
                  {new Date(betData.created_at).toLocaleDateString("en-US")}
 
                  {new Date(betData.created_at).toLocaleTimeString("en-US")}
                </div>

                <div className="row fs-14">
                  <div className="col-md-4 mb-3">
                    <div className="dark-bg-four text-center py-2">
                      <p className="mb-1">
                        <img
                          src="/assets/limbo/svg/amount.svg"
                          alt=""
                          className="w-auto"
                        />
 
                        {t("header.wallet.transactionTab.amount")}
 
                      </p>
                      <p className="mb-0">
                        {betData.amount}
                        <img
                          src={`/imgs/acceptedNetworks/${betData.network_id}.webp`}
                          className="currency-icons w-auto"
                          alt=""
                        />
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4 mb-3">
                    <div className="dark-bg-four text-center py-2">
                      <p className="mb-1">
                        <img
                          src="/assets/limbo/svg/payout.svg"
                          alt=""
                          className="w-auto"
                        />
 
                        {t("gamebox.betit.payout")}
 
                      </p>
                      <p className="mb-0">{betData.cash_out} x</p>
                    </div>
                  </div>
                  <div className="col-md-4 mb-3">
                    <div className="dark-bg-four text-center py-2">
                      <p className="mb-1">
                        <img
                          src="/assets/limbo/svg/profit.svg"
                          alt=""
                          className="w-auto"
                        />
 
                        {t("stats.mybets.profit")}
 
                      </p>
                      <p className="mb-0 text-success">
                        {betData.profit}
                        <img
                          src={`/imgs/acceptedNetworks/${betData.network_id}.webp`}
                          className="currency-icons w-auto"
                          alt=""
                        />
                      </p>
                    </div>
                  </div>
                </div>

                <div className="dark-bg-three mb-3 p-2">
                  <div className="d-flex align-items-center">
                    <div className="me-3">
                      <img
                        style={{
                          maxWidth: "60px",
                        }}
                        src="/assets/plinko/plinko-banner-img.png"
                        alt=""
                      />
                    </div>
                    <div className="flex-fill">
                      <div className="row fs-14 align-items-center">
                        <div className="col-6">
 
                          <p className="mb-0 text-white">{t("header.verificationbets.Limbo")}</p>
                          <p className="mb-0">{t("header.referral.BCWOriginals")}</p>
 
                        </div>
                        <div className="col-6 text-end">
                          <button
                            className="primary-btn primary-btn-bg"
                            onClick={handleClose}
                          >
 
                            {t("gamebox.betit.placebet")}
 
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row fs-14">
                  <div className="col-md-4 mb-3">
                    <div className="dark-bg-three text-center py-2">
                      <p className="mb-1">
                        <img
                          src="/assets/limbo/svg/result.svg"
                          alt=""
                          className="w-auto"
                        />
 
                        {t("header.verificationbets.Result")}
 
                      </p>
                      <p className="mb-0">{betData.multiplier_no}</p>
                    </div>
                  </div>
                </div>

                <div className="mb-3">
 
                  <label className="mb-2 d-block">{t("header.verificationbets.ServerSeed")}</label>
 
                  <input
                    type="text"
                    name=""
                    className="form-control"
                    value={
                      betData.exposed
                        ? betData?.server_seed
                        : t("header.verificationbets.serverseedplaceholder")
                    }
                    readOnly
                    disabled
                  />
                </div>
              </ShowComponent>

              <div className="mb-3">
                <ShowComponent condition={newSeed}>
                  <div className="mb-3">
 
                    <span className="bold white">
                      {t("header.verificationbets.CurrentSeed")}
                    </span>
                  </div>
                </ShowComponent>
                <div className="d-flex justify-content-between">
                  <label className="mb-2">
                    {t("header.verificationbets.ServerSeedhash")}
                  </label>
 
                </div>
                <input
                  type="text"
                  name=""
                  className="form-control"
                  value={betData.server_seed_hashed}
                  readOnly
                  disabled
                />
              </div>
              <div className="row">
                <div className="col-md-6 mb-3">
                  <div className="d-flex justify-content-between">
 
                    <label className="mb-2">{t("header.verificationbets.ClientSeed")}</label>
 
                  </div>
                  <input
                    type="text"
                    name=""
                    className="form-control"
                    value={betData.client_seed}
                    readOnly
                    disabled
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <div className="d-flex justify-content-between">
 
                    <label className="mb-2">{t("header.verificationbets.nonce")}</label>
 
                  </div>
                  <input
                    type="text"
                    name=""
                    className="form-control"
                    value={betData.nonce}
                    readOnly
                    disabled
                  />
                </div>
              </div>
              <ShowComponent condition={!newSeed}>
                <div className="text-center">
                  <button
                    className="orbitron-font primary-btn primary-btn-bg"
                    onClick={handleVerify}
                  >
                    <ShowComponent condition={verifyDescision}>
                      <div
                        style={{
                          top: "-45px",
                        }}
                        className="lds-ellipsis"
                      >
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    </ShowComponent>
                    <ShowComponent condition={!verifyDescision}>
 
                      {t("header.verificationbets.Verify")}
 
                    </ShowComponent>
                  </button>
                </div>
              </ShowComponent>

              {/* -------------------------------- */}
              <ShowComponent condition={newSeed}>
                <div className="mb-3">
                  <ShowComponent condition={newSeed}>
                    <div className="mb-3">
 
                      <span className="bold white">
                        {t("header.verificationbets.NewSeeds")}
                      </span>
                    </div>
                  </ShowComponent>
                  <div className="d-flex justify-content-between">
                    <label className="mb-2">
                      {t("header.verificationbets.ServerSeedhash")}
                    </label>
 
                  </div>
                  <input
                    type="text"
                    name=""
                    className="form-control"
                    value={betData.next_server_seed_hashed}
                    readOnly
                    disabled
                  />
                </div>
                <div className="row">
                  <div className="col-md-6 mb-3 relative">
                    <div className="d-flex justify-content-between">
 
                      <label className="mb-2">{t("header.verificationbets.ClientSeed")}</label>
 
                    </div>
                    <input
                      type="text"
                      name="changeAbleSeed"
                      className="form-control"
                      value={changeAbleSeed}
                      onChange={handleCliendSeed}
                    />
                    <div
                      style={{
                        right: "25px",
                        top: "55%",
                      }}
                      className="d-flex justify-content-between absolute"
                    >
                      <button className="white bold" onClick={fetchNewSeed}>
                        <AutorenewIcon />
                      </button>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="d-flex justify-content-between">
 
                      <label className="mb-2"> {t("header.verificationbets.nonce")}</label>
 
                    </div>
                    <input
                      type="text"
                      name=""
                      className="form-control"
                      value="0"
                      readOnly
                      disabled
                    />
                  </div>
                </div>
                <div className="text-center">
                  <button
                    className="orbitron-font primary-btn primary-btn-bg"
                    onClick={handleUseNewSeed}
                  >
                    <ShowComponent condition={gettingNewSeed}>
                      <div
                        style={{
                          top: "-45px",
                        }}
                        className="lds-ellipsis"
                      >
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    </ShowComponent>
                    <ShowComponent condition={!gettingNewSeed}>
 
                    {t("header.verificationbets.UseNewSeeds")}
 
                    </ShowComponent>
                  </button>
                </div>
              </ShowComponent>

              {/* ------------------------------------ */}
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default LimboPopup;
