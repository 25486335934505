import axiosClient from "..";

class KenoApis {
  static create_bet(data) {
    return axiosClient.post("/api/v1/keno/bets", data);
  }
  static my_bets() {
    return axiosClient.get("/api/v1/keno/bets");
  }
  static base_line(tiles_selected, risk) {
    return axiosClient.get(
      `/api/v1/keno/payout_lines?tiles_selected=${tiles_selected}&risk=${risk}`
    );
  }
}
export default KenoApis;
