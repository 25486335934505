import React, { useState, useEffect, useContext } from "react";
import { Box, Modal } from "@mui/material";
import "./VIPClub.css";
import AccordionItem from "./AccordionItem/AccordionItem";
import TabContent from "../../Tabs/TabContent";
import { modalsControl } from "../../../Contexts/ModalsControl";

import Undermaintenance from "../../Undermaintenance";
import ShowComponent from "../../ShowComponent/ShowComponent";
import { useTranslation } from "react-i18next";
import VipClup from "../../../../api/VIPClub/vipClub";

import { useSelector } from "react-redux";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  width: "90%",
  maxWidth: 760,

  height: "90vh",

  overflow: "auto",
  outline: "none",
};

export default function VIPClub() {
  const user_id = useSelector((state) => state.user.currentUser?.user_id);
  const { t } = useTranslation();
  const [selectedLevel, setSelectedLevel] = useState("bronze");
  const { VIPClubModal, setVipClub, setSignInOpen } = useContext(modalsControl);
  const handleClose = () => setVipClub(false);
  const handleSelectedLevel = (e) => {
    const { id } = e.target;
    setSelectedLevel(id);
  };

  const [vipData, setVipData] = useState({
    tiers: {
      bronze: 0,
      golden: 0,
      platinum: 0,
      silver: 0,
    },
    vip_progress: {
      current_level: 0,
      current_tier: 0,
      earned_xp: 0,
      next_level: 0,
      next_level_xp: 0,
      percentage_completed: 0,
      remaining_xp: 0,
    },
  });
  const [vipLoaded, setVipLoaded] = useState(false);
  const fetchVipTier = () => {
    VipClup.vip_tier()
      .then((res) => {
        setVipData(res.data);
        setVipLoaded(true);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    fetchVipTier();
  }, []);
  useEffect(() => {
    if (!user_id) {
      if (VIPClubModal) {
        setVipClub(false);
      }
      setSignInOpen(true);
    } else {
      setSignInOpen(false);
    }
  }, [user_id]);
  const levels = [
    {
      level: "bronze",
      vipFrom: "1",
      vipTo: "20",
    },
    {
      level: "silver",
      vipFrom: "21",
      vipTo: "40",
    },
    {
      level: "golden",
      vipFrom: "41",
      vipTo: "60",
    },
    {
      level: "platinum",
      vipFrom: "61",
      vipTo: "80",
    },
  ];

  return (
    <Modal
      open={true}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>

        <ShowComponent condition={!vipLoaded}>
          <div
            style={{
              left: "50%",
              position: "absolute",
              top: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: "1",
            }}
            className="lds-ellipsis"
          >
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </ShowComponent>
        <ShowComponent condition={vipLoaded}>
          <div className="vip-club">
            <p className="dark-bg-four fs-15 mb-4 p-4 radius-5 secondary_text text-center">
              {t("header.vip.toptext")}
            </p>
            <div className="align-items-center d-flex radius-5 vip-progress mb-4">
              <div className="vip-progress-left">
                <div className="position-relative">
                  <img
                    src="/imgs/vip-club/vip-progress-left.png"
                    className="w-auto"
                    alt=""
                  />
                  <div className="end-0 fs-14 position-absolute start-0 text-center vip-absolute">
                    {t("header.vip.vip")} {vipData.vip_progress.current_level}
                  </div>
                </div>
                <p>{vipData.vip_progress.current_tier}</p>
              </div>
              <div className="vip-progress-right">
                <h2 className="mb-2">{t("header.vip.progress")}</h2>
                <div className="d-flex justify-content-between mb-1">
                  <p>{t("header.vip.earned")}</p>
                  <p>{t("header.vip.remaining")}</p>
                </div>
                <div className="d-flex justify-content-between">
                  <p>
                    {vipData.vip_progress.earned_xp}
                    {t("header.vip.xp")} <i class="fa-solid fa-info"></i>
                  </p>
                  <p>
                    {vipData.vip_progress.remaining_xp}
                    {t("header.vip.xp")}
                  </p>
                </div>
                <div className="vip-club-progress-bar">
                  <div
                    style={{
                      width: `${
                        !vipData.vip_progress.percentage_completed
                          ? 0
                          : vipData.vip_progress.percentage_completed
                      }%`,
                    }}
                    className="vip-club-progress-bar-inner"
                  ></div>
                </div>
                <div>
                  <p>
                    Total {vipData.vip_progress.next_level_xp}XP for VIP{" "}
                    {vipData.vip_progress.next_level}
                  </p>

                </div>
              </div>
            </div>
            <div className="dark-bg-four fs-15 p-4 radius-5">
              <p className="dark-bg-four fs-15 radius-5 secondary_text text-center">
                {t("header.vip.discover")}
                <span className="fw-semibold">BCW.CLUB</span>
                {t("header.vip.game")}
              </p>

              {levels.map((level) => (
                <>
                  <AccordionItem
                    level={level.level}
                    vipFrom={level.vipFrom}
                    vipTo={level.vipTo}
                    onClick={handleSelectedLevel}
                  />

                  <TabContent id={level.level} activeTab={selectedLevel}>
                    <div className="vip-accrodian-content mt-3">
                      <div className="vip-accrodian-box radius-7 p-2 d-flex align-items-center">
                        <img
                          src="/imgs/vip-club/vip-accrodian-box.png"
                          className="w-auto"
                          alt=""
                        />
                        <div className="ms-3">
                          <h3 className="fs-18 mb-1">
                            {t("header.vip.level")}
                          </h3>
                          <p className="text_color">
                            {t("header.vip.totalprize")}
                            <span className="fw-semibold">
                              {vipData.tiers[level.level]}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </TabContent>
                </>
              ))}
              {/* <AccordionItem level="bronze" vipFrom="1" vipTo="20" />
            <AccordionItem level="silver" vipFrom="21" vipTo="40" /> */}

              {/* ------------------------ */}

              {/* <div className="vip-accrodian-content mt-3">
              <div className="vip-accrodian-box radius-7 p-2 d-flex align-items-center">
                <img
                  src="/imgs/vip-club/vip-accrodian-box.png"
                  className="w-auto"
                  alt=""
                />
                <div className="ms-3">
                  <h3 className="fs-18 mb-1">Level up Bonus</h3>
                  <p className="text_color">
                    Total Prize: <span className="fw-semibold">XXXXXX</span>
                  </p>
                </div>
              </div>
            </div> */}

              {/* <div class="align-items-center d-flex dark-bg-three mt-3 p-2 radius-4 pointer">
              <div class="me-3">
                <img
                  src="/imgs/vip-club/silver.png"
                  className="w-auto"
                  alt=""
                />
              </div>
              <div class="flex-fill">
                <div className="d-flex justify-content-between align-items-center ps-2">
                  <div>
                    <p className="fs-18 fw-bold mb-1">Silver</p>
                    <p>VIP 21 - VIP 40</p>
                  </div>
                  <div>
                    <img
                      src="/imgs/vip-club/vip-club-accrodian-icon.png"
                      className="w-auto"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="align-items-center d-flex dark-bg-three mt-3 p-2 radius-4 pointer">
              <div class="me-3">
                <img src="/imgs/vip-club/gold.png" className="w-auto" alt="" />
              </div>
              <div class="flex-fill">
                <div className="d-flex justify-content-between align-items-center ps-2">
                  <div>
                    <p className="fs-18 fw-bold mb-1">Gold</p>
                    <p>VIP 41 - VIP 60</p>
                  </div>
                  <div>
                    <img
                      src="/imgs/vip-club/vip-club-accrodian-icon.png"
                      className="w-auto"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="align-items-center d-flex dark-bg-three mt-3 p-2 radius-4 pointer">
              <div class="me-3">
                <img
                  src="/imgs/vip-club/platinum.png"
                  className="w-auto"
                  alt=""
                />
              </div>
              <div class="flex-fill">
                <div className="d-flex justify-content-between align-items-center ps-2">
                  <div>
                    <p className="fs-18 fw-bold mb-1">Platinum</p>
                    <p>VIP 61 - VIP 70</p>
                  </div>
                  <div>
                    <img
                      src="/imgs/vip-club/vip-club-accrodian-icon.png"
                      className="w-auto"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div> */}
            </div>
          </div>

        </ShowComponent>


      </Box>
    </Modal>
  );
}
