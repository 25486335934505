import React, { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { modalsControl } from "../../Contexts/ModalsControl";
import { useTranslation, Trans } from "react-i18next";
import Quest from "../../../api/Quest/quest";
import Countdown from "react-countdown";
import ShowComponent from "../ShowComponent/ShowComponent";
import { useNavigate } from "react-router-dom";
import "./Quest.css";
import { useDispatch, useSelector } from "react-redux";
import UserWalletsAPI from "../../../api/user_wallets";
import { setUserWallets } from "../../../store/user";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 760,
  height: "90vh",
  overflow: "auto",
};

export default function QuestModal() {
  const user_id = useSelector((state) => state.user.currentUser?.user_id);
  const dispatch = useDispatch();
  const {
    questModal,
    setQuestModal,
    handleProfileEdit,
    openTwoFa,
    setWalletOpen,
    setSignInOpen,
  } = useContext(modalsControl);

  const handleClose = () => setQuestModal(false);
  const [quests, setQuests] = useState({
    expiration_time: "00000",
    quest_tasks_running: [],
  });

  const [questLoaded, setQuestLoaded] = useState(false);

  const [wentWrnog, setWentWrong] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const fetchQuests = () => {
    if (questLoaded) {
      setQuestLoaded(false);
    }
    Quest.get_all_quest()
      .then((res) => {
        setQuestLoaded(true);
        setQuests(res.data.data);
      })
      .catch((err) => {
        setQuestLoaded(false);
        setWentWrong(true);
      });
  };
  useEffect(() => {
    fetchQuests();
  }, []);
  const getUserWallet = () => {
    UserWalletsAPI.getUserWallets()
      .then((response) => {
        dispatch(
          setUserWallets({
            wallets: response.data,
          })
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const claimReward = (id) => {
    Quest.claim({
      quests: {
        id: id,
      },
    }).then((res) => {
      fetchQuests();
      getUserWallet();
    });
  };

  const playGame = (route) => {
    handleClose();
    navigate(`/game/${route}`);
  };

  const avatarHandle = () => {
    handleProfileEdit();
    handleClose();
  };
  const emailHandle = () => {
    handleProfileEdit();
    handleClose();
  };
  const googleAuthHandle = () => {
    setWalletOpen(true);
    openTwoFa();
  };

  const avaiableTasks = {
    W_PLINKO: playGame,
    W_MINES: playGame,
    W_LIMBO: playGame,
    W_CRASH: playGame,
    P_PLINKO: playGame,
    P_MINES: playGame,
    P_LIMBO: playGame,
    P_CRASH: playGame,
    B_1: playGame,
    C_1: avatarHandle,
    C_2: emailHandle,
    C_3: googleAuthHandle,
    C_4: playGame,
  };
  const questHandle = (type, route) => {
    avaiableTasks[type](route);
  };

  useEffect(() => {
    if (!user_id) {
      if (questModal) {
        setQuestModal(false);
      }
      setSignInOpen(true);
    } else {
      setSignInOpen(false);
    }
  }, [user_id]);

  return (
    <div>
      <Modal
        open={true}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ShowComponent condition={questLoaded}>
            <div className="quest-design">
              <p className="mb-3">{t("header.quest.monday")}</p>
              <p className="mb-3">{t("header.quest.reward")}</p>
              <h3 className="mb-4 fs-22" style={{ color: "#f6c722" }}>
                <img
                  src="/imgs/quest/bcw.webp"
                  className="currency-icons me-1"
                  alt=""
                />
                {quests.accumulated_bcw} BCD
              </h3>
              <div className="d-flex justify-content-between align-items-center">
                <p>
                  {t("header.quest.daily")}
                  <img
                    src="/imgs/quest/daily-quests-info.png"
                    className="w-auto"
                    alt=""
                  />
                </p>
              </div>
              <hr
                className="my-4"
                style={{ border: "none", borderTop: "solid 1px #363636" }}
              />
              <p className="mb-3">
                {t("header.quest.exp")}
                <span className="fw-semibold">
                  &nbsp;
                  <Countdown
                    date={quests.expiration_time}
                    onComplete={fetchQuests}
                  />
                </span>
              </p>
              <div className="dark-bg-four fs-15 p-4 pt-1 radius-5">
                {quests.quest_tasks_running.map((quest) => (
                  <div class="align-items-center d-flex dark-bg-three mt-3 p-3 radius-4">
                    <div class="me-3">
                      <img
                        src="/imgs/quest/quest-reward.webp"
                        className="w-auto"
                        alt=""
                      />
                    </div>
                    <div class="flex-fill">
                      <div className="d-flex justify-content-between align-items-center ps-2">
                        <div>
                          <p className="fs-17 fw-medium mb-1">
                            {quest.quest_task}
                            {/* {t("header.quest.verification")} */}
                          </p>
                          <p className="mb-2 text_color fs-14">
                            {quest.quest_task}
                            {/* {t("header.quest.veriemail")} */}
                          </p>
                          <h3
                            className="fs-14 fw-medium"
                            style={{ color: "#F6C722" }}
                          >
                            <img
                              src="/imgs/quest/bcw.webp"
                              className="currency-icons me-1"
                              alt=""
                            />
                            {t("header.quest.earn")} {quest.reward} BCD
                          </h3>
                        </div>
                        {quest.claimed ? (
                          <div className="ms-3">
                            <button className="p-2 pe-3 primary-btn primary-btn-success ps-3">
                              Claimed
                            </button>
                          </div>
                        ) : (
                          <div className="ms-3">
                            {quest.completed ? (
                              <button
                                className="p-2 pe-3 primary-btn primary-btn-success ps-3"
                                onClick={() => claimReward(quest.id)}
                              >
                                Claim
                              </button>
                            ) : (
                              <button
                                className="p-2 pe-3 primary-btn primary-btn-bg ps-3"
                                onClick={() =>
                                  questHandle(quest.task_code, quest.route)
                                }
                              >
                                {t("header.quest.go")}
                              </button>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </ShowComponent>

          <ShowComponent condition={!questLoaded}>
            <div
              style={{
                left: "50%",
                position: "absolute",
                top: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: "1",
              }}
              className="lds-ellipsis"
            >
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </ShowComponent>

          <ShowComponent condition={wentWrnog}>
            <div className="quest-design">
              <p className="mb-3">Something went wrong</p>
            </div>
          </ShowComponent>
        </Box>

        {/* </ShowComponent> */}
      </Modal>
    </div>
  );
}
