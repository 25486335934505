import React, { useEffect } from "react";

const KenoBox = ({ value, variant, selectedBoxes, onClick }) => {
  const kenoBoxVariantss = {
    static: "keno_static",
    selected: "keno_clicked",
    server_selected: "keno_lose",
    server_matched: "keno_diamond",
  };

  return (
    <button
      className={`${
        selectedBoxes.includes(value) && variant === "static"
          ? "keno_clicked"
          : kenoBoxVariantss[variant]
      }`}
      id={value}
      onClick={onClick}
    >
      <span className="keno-digit">{value}</span>
    </button>
  );
};

export default KenoBox;
