import axiosClient from "..";

class Quest {
  static get_all_quest() {
    return axiosClient.get("/api/v1/user/quest/all");
  }
  static claim(data){
    return axiosClient.post("/api/v1/user/quest/claim",data)
  }
}
export default Quest;
