import React, { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";

import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { QRCodeCanvas } from "qrcode.react";
import TwoFactorAuth from "../../../../../api/two_factor_auth";
import { setTwoFAEnabled } from "../../../../../store/user";
import { useDispatch, useSelector } from "react-redux";
import useWindowDimensions from "../../../../Contexts/windowDimensions";
import { useTranslation } from "react-i18next";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Tooltip } from "@mui/material";
import { modalsControl } from "../../../../Contexts/ModalsControl";
function TwoFactor() {
  const { height, width } = useWindowDimensions();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // width: 450,

    width: width <= 767 ? width : "550px",
    height: width <= 767 ? height : "580px",

    borderRadius: "3px",
    background: "#1e2024",
    boxShadow: 24,
  };

  const { twoFactorModal, openTwoFa, closeTwoFa } = useContext(modalsControl);

  const handleOpen = () => openTwoFa();
  const handleClose = () => closeTwoFa(false);

  const dispatch = useDispatch();
  const [AuthQr, setAuthQr] = useState("");
  const [secretKey, setSecretKey] = useState("");

  const two_fa_enabled = useSelector((state) => state.user.two_fa_enabled);

  useEffect(() => {
    TwoFactorAuth.two_factor_auth().then((res) => {
      setSecretKey(res.data.otp_secret_key);
      setAuthQr(res.data.qrcode.qrcode.data.data);
    });
  }, []);
  const [otp, setOtp] = useState();
  const handleChange = (e) => {
    setOtp(e.target.value);
  };

  const handleClick = (e) => {
    const name = e.target.name;

    if (name == "enable") {
      TwoFactorAuth.activate_2fa_update({
        two_factor: {
          otp_response_code: otp,
          enable: true,
        },
      })
        .then((res) => {
          dispatch(
            setTwoFAEnabled({
              two_fa_enabled: true,
            })
          );
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      TwoFactorAuth.activate_2fa_update({
        two_factor: {
          otp_response_code: otp,
          enable: false,
        },
      })
        .then((res) => {
          dispatch(
            setTwoFAEnabled({
              two_fa_enabled: false,
            })
          );
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const [copied, setCopied] = useState(false);
  const keyCopy = (key) => {
    navigator.clipboard.writeText(key);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  const { t } = useTranslation();

  return (
    <div>
      <button
        style={{
          color: "#2fa5cd",
          fontFamily: "Montserrat, sans-serif",
          marginLeft: "3px",
          fontWeight: "bold",
          fontSize: "14px",
        }}
        onClick={handleOpen}
      >
        {two_fa_enabled
          ? t("header.security2Fa.disable2Fa")
          : t("header.security2Fa.enable2Fa")}
      </button>

      <Modal
        open={twoFactorModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="authentication-container">
            <div
              className="authentication-container"
              style={{ padding: "15px 25px 25px 25px" }}
            >
              <div
                style={{
                  background: "",
                  padding: width <= 767 ? "20px" : "10px",
                }}
                className="authentication-head-container my_row space-between"
              >
                <span className="orbitron-font">
                  {t("header.security2Fa.security2FA")}
                </span>

                <button className="secondary_text" onClick={handleClose}>
                  <CloseIcon />
                </button>
              </div>

              <div
                style={{
                  height: "480px",
                  padding: "10px",
                  boxSizing: "border-box",
                  overflowY: "auto",
                }}
                className="authentication-content-container width"
              >
                <div
                  style={{
                    background: "#26282d",
                    padding: "15px",
                  }}
                  className="authenticator-download-info-container centered_text"
                >
                  <span
                    style={{
                      fontSize: "14px",
                    }}
                    className="secondary_text"
                  >
                    {t("header.security2Fa.download")}
                    <a
                      style={{
                        margin: "0 5px",
                      }}
                      className="secondary_text"
                      href="https://support.google.com/accounts/answer/1066447?hl=en&rd=1"
                      target="_blacnk"
                    >
                      Google Authenticator
                    </a>
                    {t("header.security2Fa.downloadParaLeft")}
                  </span>
                </div>
                <div
                  style={{
                    margin: "20px 0px 0px 0px",
                  }}
                  className="authentication-qr-container"
                >
                  <div className="authentications-qr-top centered_text">
                    <span
                      style={{
                        fontSize: "14px",
                      }}
                      className="secondary_text"
                    >
                      {t("header.security2Fa.scanQr")}
                    </span>
                  </div>
                  <div
                    style={{
                      margin: "15px 0",
                    }}
                    className="authentications-qr"
                  >
                    {!two_fa_enabled && (
                      <div
                        style={{
                          margin: "0 auto",
                          padding: "13px",
                          height: "135px",
                          width: "135px",
                          background: "#fff",
                        }}
                        className="authentications-qr-container justified"
                      >
                        <QRCodeCanvas
                          style={{
                            padding: "5px",
                          }}
                          id="qrCode"
                          value={AuthQr}
                          size={135}
                          bgColor={"#fff"}
                          level={"H"}
                        />
                      </div>
                    )}
                  </div>
                  <br />
                  <div className="secret-key-container">
                    <div className="secret-keys-input-container">
                      <span
                        style={{
                          fontSize: "14px",
                          // marginLeft: "15px",
                        }}
                        className="secondary_text"
                      >
                        {t("header.security2Fa.yourSecretKey")}
                      </span>

                      <div className="secret-key-input relative">
                        <div
                          style={{
                            right: "15px",
                            top: "27px",
                          }}
                          className="key-copy-button-container absolute"
                        >
                          <Tooltip
                            title={copied ? "Copied" : "Copy"}
                            placement="left"
                          >
                            <button
                              className="secondary_text"
                              onClick={() => {
                                keyCopy(secretKey);
                              }}
                            >
                              <ContentCopyIcon />
                            </button>
                          </Tooltip>
                        </div>
                        <input
                          className="secondary_text"
                          value={secretKey}
                          style={{
                            background: "rgb(44 46 52)",
                            width: "100%",
                            padding: "20px 65px 20px 10px",
                            boxSizing: "border-box",
                            marginTop: "10px",
                            marginBottom: "20px",
                            textAlign: "center",
                            borderRadius: "3px",
                          }}
                          maxlength="6"
                          type="text"
                          disabled
                        />
                      </div>
                    </div>
                    <div className="secret-keys-input-container">
                      <span
                        style={{
                          fontSize: "14px",
                          // marginLeft: "15px",
                        }}
                        className="secondary_text"
                      >
                        {t("header.security2Fa.verificationCode")}
                      </span>

                      <div className="secret-key-input">
                        <input
                          value={otp}
                          onChange={handleChange}
                          style={{
                            background: "rgb(44 46 52)",
                            width: "100%",
                            padding: "20px 10px",
                            boxSizing: "border-box",
                            marginTop: "10px",
                            marginBottom: "20px",
                            textAlign: "center",
                            borderRadius: "3px",
                          }}
                          maxlength="6"
                          type="number"
                          placeholder="x-x-x-x-x-x"
                        />
                      </div>

                      <div className="authOtp-button justified">
                        {!two_fa_enabled ? (
                          <button
                            style={
                              {
                                // padding: "10px",
                                // margin: "15px",
                              }
                            }
                            name="enable"
                            className="primary-btn primary-btn-bg"
                            onClick={handleClick}
                          >
                            {t("header.security2Fa.enable2Fa")}
                          </button>
                        ) : (
                          <button
                            style={
                              {
                                // padding: "10px",
                                // margin: "15px",
                              }
                            }
                            name="disable"
                            className="primary-btn primary-btn-bg"
                            onClick={handleClick}
                          >
                            {t("header.security2Fa.disable2Fa")}
                          </button>
                        )}
                      </div>

                      <div
                        style={{
                          textAlign: "left",
                          padding: "20px 0 0 0",
                        }}
                        className="field-down-typo"
                      >
                        <span
                          style={{
                            fontSize: "14px",
                          }}
                          className="secondary_text"
                        >
                          {t("header.security2Fa.para")}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
export default TwoFactor;
