import React from "react";
import ShowComponent from "../../../../Common/ShowComponent/ShowComponent";
const Manual = ({ data, alertMessage }) => {
  const risk = [
    { intensity: "Low", value: 0 },
    { intensity: "Classic", value: 1 },
    { intensity: "Medium", value: 2 },
    { intensity: "High", value: 3 },
  ];

  return (
    <div className="manual-main-container">
      <div className="manual">Risk </div>
      <div className="mt-4">
        <select
          name="risk"
          id="risk"
          className="form-control uppercase"
          onChange={data.handleOnChange}
          disabled={data.inProgressBet}
        >
          {risk.map((risk) => (
            <option value={risk.value}>{risk.intensity}</option>
          ))}
        </select>
      </div>
      <div className="with-100 justify-content-between margin-top-15 my_row">
        {/* <button className="manual-auto-pick">
          <div>Auto Pick</div>
        </button> */}
        <button className="manual-auto-pick width" onClick={data.clearTable}>
          <div>Clear Table</div>
        </button>
      </div>
      <div className="width margin-top-15">
        <button
          className={`orbitron-font primary-btn ${
            data.inProgressBet ? "primary-btn-disable" : "primary-btn-bg"
          } bet-button-bubbles w-100`}
          onClick={data.createBet}
          disabled={data.inProgressBet}
        >
          Bet
        </button>
      </div>
      <ShowComponent condition={alertMessage}>
        <div className="alert alert-danger m-0 mt-4 mb-4 text-center">
          {alertMessage}
        </div>
      </ShowComponent>
    </div>
  );
};

export default Manual;
