import { useContext, useEffect } from "react";
import "./Deposit.css";
import Crypto from "./Crypto/Crypto";
import Fiat from "./Fiat/Fiat";
import TabNavItem from "../../../../../Tabs/TabNavItem";
import TabContent from "../../../../../Tabs/TabContent";
import { useSelector } from "react-redux";
import { modalsControl } from "../../../../../../Contexts/ModalsControl";
import { useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import axios from "axios";
import { betPriceContext } from "../../../../../../Contexts/BetPrice";
import CurrencyDropdownSelect from "../../../../../CurrencyDropdownSelect/CurrencyDropdownSelect";
import ShowComponent from "../../../../../ShowComponent/ShowComponent";

const Deposit = () => {
  const { optionState } = useContext(betPriceContext);
  const [detectedCurrency, setDetected] = useState(false);

  const wallets = useSelector((state) => state.user.wallets);

  const globalActiveWallet = wallets?.find(
    (currency) => currency.network_id == optionState
  );
  const thai_baht = wallets?.find(
    (currency) => currency.network_id == "thai_baht"
  );
  const activated =
    globalActiveWallet.type === "crypto" ? thai_baht : globalActiveWallet;
  const user_id = useSelector((state) => state.user.currentUser?.user_id);
  const { activeTab, setActiveTab, fiatDepositMethod, setFiatDepositMethod } =
    useContext(modalsControl);
  const [loadingdeposit, setLoadingDeposit] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [addressLengthErr, setAddressLengthErr] = useState(false);
  const [minAmount, setMinAmount] = useState(false);
  const [maxAmount, setMaxAmount] = useState(false);
  const [accountLengthErr, setAccountLengthErr] = useState(false);
  const [usernameLengthErr, setUserNameLengthErr] = useState(false);

  const [selectedBank, setSelectedBank] = useState({});
  const initialFormData = {
    amount: null,
    bank: "",
    phone: "",
    email: "",
    name: "",
    user_id: "",
    accountNo: "",
    address: "",
  };
  const [formDataInput, setFormData] = useState(initialFormData);
  const fiatDepositHandle = (e) => {
    const { name, id } = e.target;
    setFormData({ ...formDataInput, [name]: id });
    initialFormData.bank = id;
    setFiatDepositMethod(true);
  };

  const supported_deposit_methods = [
    {
      bank: "toppay_bca",
      method: "BCA",
      min: 10000,
      max: 100000000,
    },
    {
      bank: "toppay_bri",
      method: "BRI",
      min: 10000,
      max: 100000000,
    },
    {
      bank: "toppay_bna",
      method: "BNI",
      min: 10000,
      max: 100000000,
    },
    {
      bank: "toppay_cimb",
      method: "CIMB",
      min: 10000,
      max: 100000000,
    },
    {
      bank: "toppay_permat",
      method: "PERMATA",
      min: 10000,
      max: 100000000,
    },
    {
      bank: "toppay_mandiri",
      method: "MANDIRI",
      min: 10000,
      max: 100000000,
    },

    {
      bank: "toppay_linkaja",
      method: "LINKAJA",
      min: 20000,
      max: 10000000,
    },
    {
      bank: "toppay_ovo",
      method: "OVO",
      min: 20000,
      max: 10000000,
    },
    {
      bank: "toppay_oris",
      method: "QRIS",
      min: 20000,
      max: 10000000,
    },
    {
      bank: "toppay_dana",
      method: "DANA",
      min: 20000,
      max: 10000000,
    },
    {
      bank: "toppay_dana",
      method: "DANAMON",
      min: 10000,
      max: 100000000,
    },

    {
      bank: "toppay_alfamart",
      method: "ALFAMART",
      min: 20000,
      max: 10000000,
    },
  ];

  useEffect(() => {
    setSelectedBank(
      supported_deposit_methods.find(
        (bank) => bank.method === formDataInput.bank
      )
    );
  }, [formDataInput.bank]);
  const [showBanks, setShowBanks] = useState(false);
  const formDataOnChange = (e) => {
    if (showBanks) {
      setShowBanks(false);
    }
    const { name, value } = e.target;
    setFormData({ ...formDataInput, [name]: value });
  };

  const handlePayMoneyBlur = (e) => {
    const { name, value } = e.target;
    if (detectedCurrency) {
      if (!value || parseInt(value) <= 0) {
        setFormData({ ...formDataInput, [name]: 1 });
      }
      return;
    }
    setFormData({ ...formDataInput, [name]: parseInt(value) });
    if (!value || parseInt(value) < selectedBank?.min || parseInt(value) < 0) {
      setFormData({ ...formDataInput, [name]: selectedBank?.min });
    }
  };
  const amounts = [
    {
      amount: 10000,
    },
    {
      amount: 15000,
    },
    {
      amount: 20000,
    },
    {
      amount: 25000,
    },
  ];
  const amounts_other = [
    {
      amount: 60,
    },
    {
      amount: 100,
    },
    {
      amount: 200,
    },
    {
      amount: 2000,
    },
  ];

  useEffect(() => {
    if (detectedCurrency) {
      setFormData({ ...formDataInput, amount: 50 });
    } else {
      setFormData({ ...formDataInput, amount: 10000 });
    }
  }, [detectedCurrency]);
  const initiateDeposit = (e) => {
    e.preventDefault();

    if (loadingdeposit) {
      return;
    }

    if (detectedCurrency) {
      if (parseFloat(formDataInput.amount) < 50) {
        setMinAmount(true);
        return;
      } else {
        if (minAmount) {
          setMinAmount(false);
        }
      }
      if (parseFloat(formDataInput.amount) > 2000) {
        setMaxAmount(true);
        return;
      } else {
        if (maxAmount) {
          setMaxAmount(false);
        }
      }
      setLoadingDeposit(true);
      let data = new FormData();
      data.append("GoodsName", "TEST");
      data.append("network_id", activated.network_id);
      data.append("method", formDataInput.bank);
      data.append("Amount", formDataInput.amount);
      data.append("user_id", user_id);

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_PHP_BACKEND_LINK}/api/ax-pay/pre-paid-order`,
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          setLoadingDeposit(false);
          if (addressLengthErr) {
            setAddressLengthErr(false);
            setAccountLengthErr(false);
          }
          let a = document.createElement("a");
          a.target = "_blank";
          a.href = response.data.responseMessage;
          a.click();
        })
        .catch((error) => {
          setLoadingDeposit(false);
          if (addressLengthErr) {
            setAddressLengthErr(false);
            setAccountLengthErr(false);
          }
        });
    } else {
      setLoadingDeposit(true);
      let data = new FormData();
      data.append("productDetail", "testingProduct");
      data.append("payMoney", formDataInput.amount);
      data.append("method", formDataInput.bank);
      data.append("email", formDataInput.email);
      data.append("phone", formDataInput.phone);
      data.append("name", formDataInput.name);
      data.append("user_id", user_id);
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_PHP_BACKEND_LINK}/api/top-pay/pre-paid-order`,

        data: data,
      };
      axios
        .request(config)
        .then((response) => {
          let a = document.createElement("a");
          a.target = "_blank";
          setLoadingDeposit(false);
          a.href = response.data.responseMessage;
          a.click();
        })
        .catch((error) => {
          setLoadingDeposit(false);
        });
    }
  };
  const handleDepositAmount = (value) => {
    setFormData({ ...formDataInput, amount: parseInt(value) });
  };

  useEffect(() => {
    if (optionState === "philippine_peso" || optionState === "thai_baht") {
      setDetected(true);
    } else {
      setDetected(false);
    }
  }, [optionState]);
  const { t } = useTranslation();

  return (
    <div className="deposit-component-main-class wallet-scroll-whole relative">
      {fiatDepositMethod ? (
        <div
          className="absolute width height orbitron-font text_color"
          style={{
            left: "0",
            transition: ".3s",
            zIndex: "20",
          }}
        >
          <div
            className="withdraw-component-top-fiat my_row space-between padding-left-right"
            style={{ fontSize: "12px" }}
          >
            <div>{t("header.wallet.bankdeposit")}</div>
            <div
              onClick={() => setFiatDepositMethod(false)}
              style={{ cursor: "pointer" }}
            >
              X
            </div>
          </div>

          <div
            style={{
              padding: "0 15px",
            }}
            className="wallets-drop-down-container"
          >
            <CurrencyDropdownSelect activated={activated} text={false} />
          </div>

          <ShowComponent condition={detectedCurrency}>
            <div
              style={{
                padding: "0 15px",
              }}
              className="banks-selection-container"
            >
              <div className="bank-selection-container-main">
                <div
                  style={{
                    background: "#17181b",
                    padding: "10px 0px 10px 20px",
                  }}
                  className="bank-selection-inner my_row start"
                >
                  <div
                    style={{
                      gap: "20px",
                    }}
                    className="bank-selection-content justified"
                  >
                    <div className="selected-bank-img">
                      <img
                        style={{
                          maxWidth: "90px",
                        }}
                        name="bank"
                        className="payment-method-img"
                        src={`/imgs/banks/${formDataInput.bank}.png`}
                        alt=""
                      />
                    </div>
                    <div className="selected-bank-name">
                      <span>{formDataInput.bank}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ShowComponent>

          <form onSubmit={initiateDeposit} id="formToSubmit">
            <div
              className="withdraw-component-bottom-fiat modal-form-inputs-container padding-left-right"
              style={{ fontSize: "12px" }}
            >
              <div className="withdraw-component-bottom-fiat-content modal-input login-email">
                <div className="input-label-container input-color">
                  <div className="margin-left-10 width my_row space-between">
                    <label>{t("header.wallet.transactionTab.amount")}</label>

                    <ShowComponent condition={detectedCurrency}>
                      <ShowComponent condition={minAmount}>
                        <label className="rc">
                          Amount should not be less than 50
                        </label>
                      </ShowComponent>
                    </ShowComponent>

                    <ShowComponent condition={detectedCurrency}>
                      <ShowComponent condition={maxAmount}>
                        <label className="rc">Amount cannot exceed 2000</label>
                      </ShowComponent>
                    </ShowComponent>

                    <ShowComponent condition={!detectedCurrency}>
                      <label>
                        {t("header.wallet.withdrawTab.min")} -{" "}
                        {selectedBank?.min}
                      </label>
                    </ShowComponent>
                  </div>
                  <div>
                    <input
                      autofocus
                      name="amount"
                      type="number"
                      placeholder="Enter amount"
                      className="emailInp-text-input ModalInps white width"
                      value={formDataInput.amount}
                      onBlur={(e) => handlePayMoneyBlur(e)}
                      onChange={formDataOnChange}
                      required
                    />

                    <ShowComponent condition={detectedCurrency}>
                      <div className="user-amount-selection-main">
                        <div className="user-amount-selection-inner width my_row">
                          {amounts_other?.map((element) => (
                            <div
                              name="amount"
                              style={{
                                padding: "10px",
                                marginRight: "10px",
                              }}
                              className={`user-amount-selection-button pointer primary-btn ${
                                element.amount == formDataInput.amount
                                  ? "amount-button-enabled"
                                  : "amount-button-disable"
                              }`}
                              onClick={(e) =>
                                handleDepositAmount(element.amount)
                              }
                            >
                              {element.amount}
                            </div>
                          ))}
                        </div>
                      </div>
                    </ShowComponent>
                    <ShowComponent condition={!detectedCurrency}>
                      <div className="user-amount-selection-main">
                        <div className="user-amount-selection-inner width my_row">
                          {amounts?.map(
                            (element) =>
                              parseFloat(element.amount) >=
                                parseFloat(selectedBank?.min) && (
                                <div
                                  name="amount"
                                  style={{
                                    padding: "10px",
                                    marginRight: "10px",
                                  }}
                                  className={`user-amount-selection-button pointer primary-btn ${
                                    element.amount == formDataInput.amount
                                      ? "amount-button-enabled"
                                      : "amount-button-disable"
                                  }`}
                                  onClick={(e) =>
                                    handleDepositAmount(element.amount)
                                  }
                                >
                                  {element.amount}
                                </div>
                              )
                          )}
                        </div>
                      </div>
                    </ShowComponent>
                  </div>
                </div>

                <ShowComponent condition={!detectedCurrency}>
                  <div className="input-label-container input-color">
                    <div className="margin-left-10">
                      <label>{t("header.wallet.bankname")}</label>
                    </div>
                    <select
                      name="bank"
                      className="emailInp-text-input ModalInps input-color width border-none"
                      value={formDataInput.bank}
                      onChange={formDataOnChange}
                    >
                      {supported_deposit_methods.map((bank) => (
                        <option value={bank.method}>{bank.method}</option>
                      ))}
                    </select>
                  </div>
                </ShowComponent>

                <ShowComponent condition={!detectedCurrency}>
                  <div className="input-label-container input-color">
                    <div className="margin-left-10 width my_row space-between">
                      <label>{t("header.wallet.name")}</label>
                      <ShowComponent condition={usernameLengthErr}>
                        <label className="rc">
                          Length should be 3 characters atleast
                        </label>
                      </ShowComponent>
                    </div>
                    <div>
                      <input
                        type="text"
                        name="name"
                        className="emailInp-text-input ModalInps input-color width"
                        value={formDataInput.name}
                        onChange={formDataOnChange}
                        required
                      />
                    </div>
                  </div>
                </ShowComponent>

                <ShowComponent condition={!detectedCurrency}>
                  <div className="input-label-container input-color">
                    <label>{t("header.wallet.phone")}</label>
                    <div>
                      <input
                        type="tel"
                        name="phone"
                        className="emailInp-text-input ModalInps input-color width"
                        value={formDataInput.phone}
                        onChange={formDataOnChange}
                        required
                      />
                    </div>
                  </div>
                </ShowComponent>
                <ShowComponent condition={!detectedCurrency}>
                  <div className="input-label-container input-color">
                    <div className="margin-left-10">
                      <label>{t("header.signin.email")}</label>
                    </div>
                    <div>
                      <input
                        type="email"
                        name="email"
                        className="emailInp-text-input ModalInps input-color width"
                        value={formDataInput.email}
                        onChange={formDataOnChange}
                        required
                      />
                    </div>
                  </div>
                </ShowComponent>
                <div className="justify my_row margin-top-30 margin-bottom-30">
                  <button
                    type="submit"
                    className="primary-btn primary-btn-bg orbitron-font"
                  >
                    {loadingdeposit ? (
                      <div
                        style={{
                          top: "-45px",
                        }}
                        className="lds-ellipsis"
                      >
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    ) : (
                      t("header.wallet.confirm")
                    )}
                  </button>
                </div>
                <div className="last-div">
                  <div>
                    <img
                      src="/imgs/safety.webp"
                      alt=""
                      style={{ width: "50px", Height: "50px" }}
                    />
                  </div>
                  <div className="input-label-container input-color rtl-right-hand-css">
                    <div className="margin-left-10 rtl-right-hand-css ">
                      {t("header.wallet.safty")}
                    </div>
                    <div className="margin-left-10 rtl-right-hand-css">
                      {t("header.wallet.safety")}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      ) : (
        <div className="deposit-component-inner-class">
          <div className="deposit-component-content-class">
            <div className="tabs-scroll">
              <div className="tabs-navs justified">
                <TabNavItem
                  title="Crypto"
                  id="crypto"
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                />
                <TabNavItem
                  title="Fiat"
                  id="fiat"
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                />
              </div>
            </div>
            <div className="deposit-bottom-container">
              <TabContent id="crypto" activeTab={activeTab}>
                <div className="for-crypto">
                  <Crypto activeWallets={wallets} walletType={activeTab} />
                </div>
              </TabContent>
              <TabContent id="fiat" activeTab={activeTab}>
                <div className="for-fiat">
                  <Fiat
                    activeWallets={wallets}
                    walletType={activeTab}
                    handleClick={fiatDepositHandle}
                  />
                </div>
              </TabContent>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Deposit;
