import "./Mines.css";
import React, { useState, useContext, useEffect } from "react";
import useSound from "use-sound";
import CashoutSound from "./Addons/Sound/win.mp3";
import WinSound from "./Addons/Sound/gems.mp3";
import loseSound from "./Addons/Sound/mines.mp3";
import playSound from "./Addons/Sound/bet.mp3";
import PlayGround from "./Elements/PlayGround";
import MineArea from "./Elements/MineArea";
import BetStats from "./Elements/Stats/Stats";
// Contexts import
import { betPriceContext } from "../../Contexts/BetPrice";
// Redux state setter imports
import { setUserWallets } from "../../../store/user";
// API imports
import UserWalletsAPI from "../../../api/user_wallets";
import MinesAPis from "../../../api/Mines/mines";
import PoolAPI from "../../../api/pool";
import { modalsControl } from "../../Contexts/ModalsControl";
import { setMinesBetData } from "../../../store/mine";
import MinesApis from "../../../api/Mines/mines";
import { useDispatch, useSelector } from "react-redux";
import BetConfigration from "../../../api/currency_bet_configrations";
import LoadingScreens from "../../Common/LoadingScreens/LoadingScreens";
import { Select } from "@mui/material";
import MaxBetAlert from "../../Common/MaxBetAlert/MaxBetAlert";
import ShowComponent from "../../Common/ShowComponent/ShowComponent";

const Mines = () => {
  const [MinesSound] = useSound(loseSound);
  const [GemsSound] = useSound(WinSound);
  const [CreateSound] = useSound(playSound);
  const [WinCashoutSound] = useSound(CashoutSound);
  // Redux states
  const dispatch = useDispatch();
  const user_id = useSelector((state) => state.user.currentUser?.user_id);
  // Context states
  const { activatedCurrency } = useContext(betPriceContext);
  // Helper Variables
  const inputInitials = {
    betAmount: 1,
    mines: 1,
    network_id: activatedCurrency.network_id,
    autoBets: 0,
    increaseOnWin: 0,
    decreaseOnLoss: 0,
    autoProfitStop: 0,
    stopProfit: 0,
    stopLoss: 0,
  };
  const myClass = "mines-selected";
  const { setSignInOpen, showTrends, setShowTrends } =
    useContext(modalsControl);
  const [alertMessage, setAlertMessage] = useState("");
  const [placed, setPlaced] = useState(false);
  const [inProgressBet, setInprogressBet] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [myBets, setMyBets] = useState([]);
  const [pool_amounts, setPoolAmounts] = useState([]);
  const [betValues, setBetValues] = useState(inputInitials);
  const [selectedPool, setSelectedPool] = useState([]);
  const [fetchRuningBet, setFetchRuningBet] = useState(true);
  const [currencyConfigrations, setCurrencyConfigrations] = useState([]);
  const [cashOutPopup, setCashOutPopup] = useState(false);
  const [activeBetMines, setActiveBetMines] = useState(1);
  const [activeBetWallet, setActoiveBetWallet] = useState("");
  const [mode, setMode] = useState("manual");
  const [autoPlacedBets, setAutoPlacedBets] = useState(0);
  const [calculatedAutoProfit, setCalculatedAutoProfit] = useState(0);
  const [calculatedAutoLoss, setCalculatedAutoLoss] = useState(0);
  const [onWinMode, setOnWinMod] = useState("reset");
  const [onLossMode, setOnLossMode] = useState("reset");
  const [betAmountToReset, setBetAmountToReset] = useState(0);
  const [autoBetInProgress, setAutoBetInProgress] = useState(false);
  const [maxProfitApplied, setMaxProfitApplied] = useState(false);
  const [winStatus, setWinStatus] = useState(null);
  const [maxBetAlert, setMaxBetAlert] = useState(false);
  const [dontShowAlert, setDontShowAlert] = useState(false);
  // Bet Associated States
  const [configuredCurrency, setConfiguredCurrency] = useState({});
  const [selectedBoxes, setSelectedBoxes] = useState([]);
  // Helper functions
  const findConfiguredCurrency = () => {
    setConfiguredCurrency(
      currencyConfigrations?.find(
        (currency) => currency.network_id === activatedCurrency.network_id
      )
    );
  };
  const [grid, setGrid] = useState([]);

  useEffect(() => {
    BetConfigration.bet_configrations("mines")
      .then((res) => {
        setCurrencyConfigrations(res.data.currency_configuration);
      })
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    findConfiguredCurrency();
  }, [currencyConfigrations.length, activatedCurrency.network_id]);

  const HideBoxes = () => {
    MinesAPis.getbox()
      .then((res) => {
        setGrid(res.data.data.tile_inedx_array);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    HideBoxes();
  }, []);

  const handleAutoBet = () => {
    if (selectedBoxes.length > 0) {
      setBetAmountToReset(betValues.betAmount);
      setAutoBetInProgress(!autoBetInProgress);
    } else {
      return;
    }
  };

  const handleMode = (e) => {
    const { id } = e.target;
    setMode(id);
    HideBoxes();
    setCashOutPopup(false);
  };

  const handleAutoBetPlacer = () => {
    if (
      parseFloat(betValues.betAmount) >=
        parseFloat(configuredCurrency.max_bet) &&
      !maxBetAlert &&
      !dontShowAlert
    ) {
      if (!dontShowAlert) {
        setMaxBetAlert(true);
      }
    } else {
      if (maxBetAlert) {
        setMaxBetAlert(false);
      }
      if (!user_id) {
        setSignInOpen(true);
        return;
      }
      if (inProgressBet) {
        return;
      }
      setInprogressBet(true);
      MinesApis.auto_bet({
        mine_param: {
          amount: parseFloat(betValues.betAmount),
          mine: parseFloat(betValues.mines),
          network_id: activatedCurrency.network_id,
          user_tiles: selectedBoxes,
        },
      })
        .then((res) => {
          CreateSound();
          setGrid(res.data.data.revealed_tiles.tiles_arry);
          setWinStatus(res.data.data.win_status);
          if (res.data.data.win_status) {
            GemsSound();
            setCashOutPopup(true);
            setMaxProfitApplied(res.data.data.max_profit_applied);
            setCalculatedAutoProfit(
              calculatedAutoProfit + parseFloat(res.data.data.profit)
            );
            let percentage =
              (parseFloat(betValues.betAmount) / 100) *
              parseFloat(betValues.increaseOnWin);
            if (autoBetInProgress) {
              if (calculatedAutoLoss - parseFloat(res.data.data.profit) > 0) {
                setCalculatedAutoLoss(
                  calculatedAutoLoss - parseFloat(res.data.data.profit)
                );
              } else {
                setCalculatedAutoLoss(0);
              }

              if (onWinMode === "increase") {
                if (
                  parseFloat(betValues.betAmount) + parseFloat(percentage) >
                  parseFloat(configuredCurrency.max_bet)
                ) {
                  setBetValues({
                    ...betValues,
                    betAmount: parseFloat(configuredCurrency.max_bet).toFixed(
                      configuredCurrency?.precision
                    ),
                  });
                } else {
                  setBetValues({
                    ...betValues,
                    betAmount: (
                      parseFloat(betValues.betAmount) + percentage
                    ).toFixed(configuredCurrency?.precision),
                  });
                }
              } else {
                setBetValues({
                  ...betValues,
                  betAmount: parseFloat(betAmountToReset).toFixed(
                    configuredCurrency?.precision
                  ),
                });
              }
            }
          } else {
            MinesSound();
            let percentage =
              (parseFloat(betValues.betAmount) / 100) *
              parseFloat(betValues.decreaseOnLoss);

            setCalculatedAutoLoss(
              calculatedAutoLoss + parseFloat(res.data.data.amount)
            );

            if (autoBetInProgress) {
              if (calculatedAutoProfit - parseFloat(res.data.data.profit) > 0) {
                setCalculatedAutoProfit(
                  calculatedAutoProfit - parseFloat(res.data.data.profit)
                );
              } else {
                setCalculatedAutoProfit(0);
              }
              if (onLossMode === "increase") {
                if (
                  parseFloat(betValues.betAmount) + parseFloat(percentage) >
                  parseFloat(configuredCurrency.max_bet)
                ) {
                  setBetValues({
                    ...betValues,
                    betAmount: parseFloat(configuredCurrency.max_bet).toFixed(
                      configuredCurrency?.precision
                    ),
                  });
                } else {
                  setBetValues({
                    ...betValues,
                    betAmount: (
                      parseFloat(betValues.betAmount) + percentage
                    ).toFixed(configuredCurrency?.precision),
                  });
                }
              } else {
                setBetValues({
                  ...betValues,
                  betAmount: parseFloat(betAmountToReset).toFixed(
                    configuredCurrency?.precision
                  ),
                });
              }
            }
          }
          fetchBetsHistory();
          fetchPoolAmounts();
          fetchUserWallets();
          setTimeout(() => {
            HideBoxes();
            setCashOutPopup(false);
            setInprogressBet(false);
            setMaxProfitApplied(false);
            setAutoPlacedBets(autoPlacedBets + 1);
          }, 1000);
          dispatch(
            setMinesBetData({
              current_multiplier: res.data.data.current_multiplier,
              current_payout: res.data.data.current_payout,
            })
          );
        })
        .catch((err) => {
          setInprogressBet(false);
          setAutoBetInProgress(false);
          setAlertMessage(err.response.data.message);
          setTimeout(() => {
            setAlertMessage("");
          }, 3000);
        });
    }
  };

  const showboxes = () => {
    MinesAPis.showbox()
      .then((res) => {
        setGrid(res.data.data.revealed_tiles_arry);
        setInprogressBet(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCheckAuto = (e) => {
    let { id } = e.target;
    id = parseInt(id);

    if (selectedBoxes.includes(id)) {
      setSelectedBoxes(selectedBoxes.filter((buttonId) => buttonId !== id));
      e.currentTarget.classList.toggle("mines-selected");
    } else if (selectedBoxes.length < 25 - betValues.mines) {
      setSelectedBoxes([...selectedBoxes, id]);
      e.currentTarget.classList.toggle("mines-selected");
    } else {
      return;
    }
  };

  useEffect(() => {
    console.log("here is selectedBoxes", selectedBoxes);
  }, [selectedBoxes]);

  const handleCheckBlock = (e) => {
    const { id } = e.target;
    MinesAPis.bet_update({
      update_param: {
        tile_index: parseInt(id),
      },
    })
      .then((res) => {
        if (res.data.data.remaining_gems === 0) {
          betPayout();
        } else {
          let triggeredBlock = grid.find(
            (block) => block.id === parseFloat(id)
          );
          triggeredBlock.revealed = true;
          triggeredBlock.status = "revealed";
          triggeredBlock.type = res.data.data.type;
          dispatch(
            setMinesBetData({
              next_payout: res.data.data.next_payout,
              next_multiplier: res.data.data.next_multiplier,
              current_multiplier: res.data.data.current_multiplier,
              current_payout: res.data.data.current_payout,
              remaining_gems: res.data.data.remaining_gems,
            })
          );
          if (res.data.data.type === "mine") {
            showboxes();
            fetchBetsHistory();
            setPlaced(false);
            MinesSound();
          } else {
            GemsSound();
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const RandomlyTile = () => {
    MinesApis.rendompicktail()
      .then((res) => {
        if (res.data.data.remaining_gems === 0) {
          betPayout();
        } else {
          let triggeredBlock = grid.find(
            (block) => block.id === res.data.data.index
          );
          triggeredBlock.revealed = true;
          triggeredBlock.status = "revealed";
          triggeredBlock.type = res.data.data.type;
          dispatch(
            setMinesBetData({
              next_payout: res.data.data.next_payout,
              next_multiplier: res.data.data.next_multiplier,
              current_multiplier: res.data.data.current_multiplier,
              current_payout: res.data.data.current_payout,
              remaining_gems: res.data.data.remaining_gems,
            })
          );
          if (res.data.data.type === "mine") {
            setPlaced(false);
            showboxes();
            MinesSound();
            fetchUserWallets();
            fetchBetsHistory();
          } else {
            GemsSound();
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchPoolAmounts = () => {
    PoolAPI.getPoolAmounts("mines").then((response) => {
      setPoolAmounts(response?.data?.pool_values);
    });
  };
  const fetchActiveBets = () => {
    if (!fetchRuningBet) {
      setFetchRuningBet(true);
    }
    MinesAPis.active_bet()
      .then((res) => {
        setInprogressBet(true);
        setFetchRuningBet(false);
        setPlaced(true);
        setGrid(res.data.data.tiles_arry);
        setActiveBetMines(res.data.data.mines);
        setBetValues({ ...betValues, betAmount: res.data.data.amount });
        setActoiveBetWallet(res.data.data.network_id);
        dispatch(
          setMinesBetData({
            next_payout: res.data.data.next_payout,
            next_multiplier: res.data.data.next_multiplier,
            current_multiplier: res.data.data.current_multiplier,
            current_payout: res.data.data.current_payout,
            remaining_gems: res.data.data.remaining_gems,
          })
        );
      })
      .catch((err) => {
        setInprogressBet(false);
        setFetchRuningBet(false);
      });
  };

  const fetchBetsHistory = () => {
    if (user_id) {
      setIsLoading(true);
      MinesAPis.my_bets()
        .then((response) => {
          setMyBets(response.data.data.user_bets);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  useEffect(() => {
    if (!user_id) {
      setFetchRuningBet(false);
      setMyBets([]);
      if (placed) {
        setPlaced(false);
      }
      return;
    }
    fetchBetsHistory();
    fetchActiveBets();
  }, [user_id]);
  const fetchUserWallets = () => {
    UserWalletsAPI.getUserWallets().then((response) => {
      dispatch(
        setUserWallets({
          wallets: response.data,
        })
      );
    });
  };

  const handleBetPlace = () => {
    if (!user_id) {
      setSignInOpen(true);
      return;
    }
    if (inProgressBet) {
      return;
    }
    MinesAPis.create_bet({
      mine_param: {
        amount: betValues.betAmount,
        mine: betValues.mines,
        network_id: activatedCurrency.network_id,
      },
    })
      .then((res) => {
        CreateSound();
        HideBoxes();
        setCashOutPopup(false);
        setPlaced(true);
        setInprogressBet(true);
        setActoiveBetWallet(activatedCurrency.network_id);
        fetchBetsHistory();
        fetchPoolAmounts();
        fetchUserWallets();
        dispatch(
          setMinesBetData({
            next_payout: res.data.data.next_payout,
            next_multiplier: res.data.data.next_multiplier,
            current_multiplier: res.data.data.current_multiplier,
            current_payout: res.data.data.current_payout,
            remaining_gems: res.data.data.remaining_gems,
          })
        );
      })
      .catch((err) => {
        setInprogressBet(false);
        setAlertMessage(err.response.data.message);
        setTimeout(() => {
          setAlertMessage("");
        }, 3000);
      });
  };
  // Fetch my history & pool amounts
  useEffect(() => {
    fetchPoolAmounts();
  }, []);
  // Select the desired pool
  useEffect(() => {
    setSelectedPool(
      pool_amounts?.find(
        (element) => element.network_id === activatedCurrency?.network_id
      )
    );
  }, [activatedCurrency?.network_id, pool_amounts]);

  useEffect(() => {
    setBetValues({ ...betValues, mines: activeBetMines });
  }, [fetchRuningBet]);

  const betPayout = () => {
    MinesAPis.bet_cashout()
      .then((res) => {
        fetchUserWallets();
        showboxes();
        setPlaced(false);
        setCashOutPopup(true);
        setInprogressBet(false);
        fetchBetsHistory();
        WinCashoutSound();
        dispatch(
          setMinesBetData({
            next_payout: 0,
            next_multiplier: 0,
            current_multiplier: res.data.data.multiplier,
            current_payout: res.data.data.payout,
            remaining_gems: 25 - betValues.mines,
          })
        );
      })
      .catch((err) => {
        setInprogressBet(false);
        setAlertMessage(err.response.data.message);
        setTimeout(() => {
          setAlertMessage("");
        }, 3000);
      });
  };

  useEffect(() => {
    dispatch(
      setMinesBetData({
        remaining_gems: 25 - betValues.mines,
      })
    );
  }, [betValues.mines]);

  useEffect(() => {
    if (showTrends) {
      setShowTrends(false);
    }
  }, []);

  useEffect(() => {
    if (
      parseInt(autoPlacedBets) < parseInt(betValues.autoBets) &&
      autoBetInProgress &&
      parseInt(betValues.autoBets) - autoPlacedBets > 0
    ) {
      handleAutoBetPlacer();
    } else if (autoBetInProgress && parseInt(betValues.autoBets) == 0) {
      handleAutoBetPlacer();
    } else {
      setAutoBetInProgress(false);
      setAutoPlacedBets(0);
    }
  }, [autoPlacedBets, autoBetInProgress]);

  useEffect(() => {
    if (parseFloat(betValues.stopProfit) !== 0) {
      if (
        parseFloat(calculatedAutoProfit) >= parseFloat(betValues.stopProfit)
      ) {
        setAutoBetInProgress(false);
        setCalculatedAutoProfit(0);
      }
    }
    if (parseFloat(betValues.stopLoss) !== 0) {
      if (parseFloat(calculatedAutoLoss) >= parseFloat(betValues.stopLoss)) {
        setAutoBetInProgress(false);
        setCalculatedAutoLoss(0);
      }
    }
  }, [calculatedAutoProfit, calculatedAutoLoss]);

  useEffect(() => {
    document.title =
      "Mines Game: Discover the world of Crypto Mines Game - Start mining today!";
  }, []);
  if (fetchRuningBet) {
    return <LoadingScreens screen="mines" />;
  } else
    return (
      <div className="main-wrapper-container">
        <div className="row m-0">
          <ShowComponent condition={maxBetAlert}>
            <MaxBetAlert
              maxBetAlert={maxBetAlert}
              setMaxBetAlert={setMaxBetAlert}
              dontShowAlert={dontShowAlert}
              setDontShowAlert={setDontShowAlert}
              handleAutoBetPlacer={handleAutoBetPlacer}
              setAutoBetInProgress={setAutoBetInProgress}
              autoBetInProgress={autoBetInProgress}
              inProgressBet={inProgressBet}
              setInprogressBet={setInprogressBet}
            />
          </ShowComponent>

          <PlayGround
            activatedCurrency={activatedCurrency}
            myBets={myBets}
            selectedPool={selectedPool}
            grid={grid}
            setGrid={setGrid}
            cashOutPopup={cashOutPopup}
            betValues={betValues}
            handleCheckBlock={handleCheckBlock}
            fetchBetsHistory={fetchBetsHistory}
            selectedBoxes={selectedBoxes}
            setSelectedBoxes={setSelectedBoxes}
            mode={mode}
            setMode={setMode}
            handleCheckAuto={handleCheckAuto}
            myClass={myClass}
          />
          <MineArea
            alertMessage={alertMessage}
            configuredCurrency={configuredCurrency}
            betPlacer={handleBetPlace}
            RandomlyTile={RandomlyTile}
            betValues={betValues}
            setBetValues={setBetValues}
            placed={placed}
            cashout={betPayout}
            inProgressBet={inProgressBet}
            activeBetWallet={activeBetWallet}
            handleAutoBet={handleAutoBet}
            autoPlacedBets={autoPlacedBets}
            setAutoPlacedBets={setAutoPlacedBets}
            autoBetInProgress={autoBetInProgress}
            calculatedAutoProfit={calculatedAutoProfit}
            calculatedAutoLoss={calculatedAutoLoss}
            onWinMode={onWinMode}
            setOnWinMod={setOnWinMod}
            onLossMode={onLossMode}
            setOnLossMode={setOnLossMode}
            mode={mode}
            setMode={setMode}
            handleCheckBlock={handleCheckBlock}
            myClass={myClass}
            selectedBoxes={selectedBoxes}
            handleMode={handleMode}
          />
        </div>
      </div>
    );
};

export default Mines;
