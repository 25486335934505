import axiosClient from "..";

class ProviderApis {
  static create_bet(data) {
    return axiosClient.post("/api/v1/provider/bet_create", data);
  }
  static cashout(data) {
    return axiosClient.patch("/api/v1/provider/bet_cashout", data);
  }
}
export default ProviderApis;
