import React from "react";
import "./StatsCss.css";
import MyHistory from "./Tabs/MyHistory";
import { useTranslation } from "react-i18next";

const Stats = ({ myBets, loadingGame }) => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        height: "550px",
      }}
      className="stats-main-div relative"
    >
      <div className="stats-inner-div">
        <div className="history-myHistory-switch my_row_item">
          <button
            name="MyBets"
            className="myHistory my_row_item_2 stats-button stats-button-active"
          >
            {t("stats.mybetstxt")}
          </button>
        </div>
        <div className="history-myHistory-data-container margin-top-30">
          <MyHistory myBets={myBets} loadingGame={loadingGame} />
        </div>
      </div>
    </div>
  );
};

export default Stats;
