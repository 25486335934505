import React, { useState } from "react";
import Router from "./router/Router";
import { useContext } from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { multiplierContext } from "./components/Contexts/Multiplier";
import { betStatusSubscribe } from "./channels/bet_status_channel";
import LogRocket from "logrocket";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import i18n from "i18next";
import Undermaintenance from "./components/Common/Undermaintenance";
import ShowComponent from "./components/Common/ShowComponent/ShowComponent";

const CLIENT_ID =
  "913866069123-uk191o2493bl7casbtbhj5kdhnp0cpih.apps.googleusercontent.com";
const App = () => {
  useEffect(() => {
    const browserLanguage = navigator.language || navigator.userLanguage;
    const supportedLanguages = ['en', 'ar', 'id', 'th'];
    const defaultLanguage = 'en';
    let selectedLanguage = defaultLanguage;

    if (supportedLanguages.includes(browserLanguage)) {
      selectedLanguage = browserLanguage;
    }

    i18n.changeLanguage(selectedLanguage);
    setBodyClass(selectedLanguage);
  }, [i18n]);

  const setBodyClass = (language) => {
    const element = document.getElementById('body');
    element.className = language === 'ar' ? 'right-hand-css' : '';
  };
  const selected_user_profile = useSelector((state) => state.user.user_profile);
  if (process.env.REACT_APP_ENVIRONMENT == "production") {
    LogRocket.init("nkucct/bcw");
    LogRocket.identify(selected_user_profile?.user, {
      name: selected_user_profile?.username,
      email: selected_user_profile?.email,

      // Add your own custom user variables here, ie:
      subscriptionType: "pro",
    });
  }

  const {
    clientSocket,
    setMyChannel,
    setCreateBetChannel,
    setUpdateBetChannel,
    myChannel,
  } = useContext(multiplierContext);
  (async () => {
    for await (let event of clientSocket.listener("connect")) {
      setMyChannel(clientSocket.subscribe("round-state"));
      setCreateBetChannel(clientSocket.subscribe("create-bet"));
      setUpdateBetChannel(clientSocket.subscribe("update-bet"));
      setTimeout(() => {
        betStatusSubscribe();
      }, 1000);
    }
  })();
  (async () => {
    await myChannel?.listener("subscribe").once();
  })();

  const [undermaintenance, setUndermaintenence] = useState(false);
  return (
    <GoogleOAuthProvider clientId={CLIENT_ID}>
      <ShowComponent condition={!undermaintenance}>
        <Router />
      </ShowComponent>
      <ShowComponent condition={undermaintenance}>
        <Undermaintenance />
      </ShowComponent>
    </GoogleOAuthProvider>
  );
};

export default App;
