import React, { memo, useContext } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Badge from "./SVGS/badge.svg";
import UserProfile from "./SVGS/userProfile.png";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import CasinoIcon from "@mui/icons-material/Casino";
import CoinStack from "./SVGS/coin-stack-svgrepo-com.svg";
import { useSelector, useDispatch } from "react-redux";
import UserProfilesAPI from "../../../../../api/user_profiles";
import { useNavigate } from "react-router-dom";
import { setSelectedUserProfile } from "../../../../../store/game";
// import Medal from "./SVGS/medal.webp";
// import Empty from "./SVGS/empty.webp";
import EditIcon from "@mui/icons-material/Edit";

import "./index.css";
// import BasicModal from "./BasicModal";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import UserProfileImg from "./UserProfileImg";
import { useState, useEffect } from "react";
import { setUserProfile } from "../../../../../store/user";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import useWindowDimensions from "../../../../Contexts/windowDimensions";
import Tooltip from "@mui/material/Tooltip";
import SignInAPI from "../../../../../api/signin";
import KeyIcon from "@mui/icons-material/Key";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import GppMaybeIcon from "@mui/icons-material/GppMaybe";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import SendIcon from "@mui/icons-material/Send";
import DoneIcon from "@mui/icons-material/Done";
import { setVerified } from "../../../../../store/user";
import { useTranslation } from "react-i18next";
import AddLinkIcon from "@mui/icons-material/AddLink";
import ReferralModal from "../../../../Common/Modals/ReferralModal";
import { modalsControl } from "../../../../Contexts/ModalsControl";
import ShowComponent from "../../../../Common/ShowComponent/ShowComponent";

function BasicModal({ Text, userId, UniqueClass, userImg }) {
  const { height, width } = useWindowDimensions();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",

    width: width <= 767 ? width : 500,

    boxShadow: 24,
    background: "#17181b",
    color: "#f5f6f7",
    padding: "0 20px",

    overflowY: "scroll",
    overflowX: "hidden",
    position: "relative",
  };

  const verified = useSelector((state) => state.user.verified);
  const [isUpdating, setisUpdating] = useState(false);

  const [editableProfile, setEditableProfile] = useState(false);
  const [editablePassword, setEditablePassword] = useState(false);

  const selected_user_profile = useSelector(
    (state) => state.game.selected_user_profile
  );

 
  const [selectedFile, setSelectedFile] = useState();

  const enableUpdating = () => {
    setisUpdating(true);
  };
  const disableUpdating = () => {
    setisUpdating(false);
  };

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    // setIsSelected(true);
    if (!event.target.files || event.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }

    // I've kept this example simple by using the first image instead of multiple
    // setSelectedFile(e.target.files[0])
  };

  const [userData, setUserData] = useState({
    first_name: "",
    joining_date: "",
    last_name: "",
    total_bets: "",
    total_wins: "",
    user: "",
    user_img: "",
    username: "",
    wagered_amount: "",
  });

  const [copy, setCopy] = useState(false);

  const handleCopy = (tocopy) => {
    navigator.clipboard.writeText(tocopy);
    setCopy(true);
    setTimeout(() => {
      setCopy(false);
    }, 3000);
  };


  const [updatedImage, setUpdatedImage] = useState();
  const [fullName, setFullName] = useState("");
  const [currentPassword, setCurrentpassword] = useState("");
  const [canSee, setCanSee] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [alert, setAlert] = useState(false);
  const [empty, setEmpty] = useState(false);
  const [notMatched, setNotMatched] = useState(false);
  const [notMatchedMessage, setNotMatchedMessage] = useState(false);
  const [currentWrong, setCurrentWrong] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [verificationSent, setVerificationSent] = useState(false);
  const [changeSuccess, setChangeSuccess] = useState(false);
  const [type, setType] = useState("");
  const [message, setMessage] = useState("");
  const [minPassword, setMinPassword] = useState(false);
  const [updatedPassword, setUpdatedPassword] = useState(false);
  const [updatingPassword, setUpdatingPassword] = useState(false);
  // const [fileToUpload, setFileToUpload] = useState();
  const [preview, setPreview] = useState();

  const [updatedUserName, setUpdatedUsername] = useState("");
  const [signinEmail, setSigninEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const { profileModal, setProfileModal, slider, setSlider } =
    useContext(modalsControl);

  const getSetUserProfile = () => {
    UserProfilesAPI.get_signin_user_profile()
      .then((response) => {
        setUpdatedUsername(response.data.user_profile.username);
        setSigninEmail(response.data.user_profile.email);
        setFirstName(response.data.user_profile.first_name);
        setLastName(response.data.user_profile.last_name);
        setUserData(response.data.user_profile);

        dispatch(
          setUserProfile({
            user_profile: response.data.user_profile,
          })
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const [showData, setShowData] = useState(false);
  const fetchSelectedUserProfile = () => {
    UserProfilesAPI.get_user_profile({
      id: userId || 103883,
    })
      .then((response) => {
        setShowData(true);
        dispatch(
          setSelectedUserProfile({
            selected_user_profile: response.data.user_profile,
          })
        );
      })
      .catch((err) => console.log(err));
  };
  const handleOpen = () => {
    setProfileModal(true);
    // fetchSelectedUserProfile();
    getSetUserProfile();
  };
  useEffect(() => {
    if (profileModal) {
      fetchSelectedUserProfile();
    }
  }, [profileModal]);
  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const dispatch = useDispatch();
  const handleClose = (e) => {
    setSelectedFile();
    setProfileModal(false);
    setSlider(false);
  };
  const slideFunction = () => {
    setSlider(true);
  };
  const slideBack = () => {
    setSlider(false);
  };
  const handlePressModify = () => {
    enableUpdating();
    if (updatedUserName) {
      UserProfilesAPI.set_user_username({
        user: {
          username: updatedUserName,
        },
      })
        .then((response) => {
          disableUpdating();
          getSetUserProfile();
        })
        .catch((error) => {
          disableUpdating();
          console.log(error);
        });
    }
    if (selectedFile) {
      let formData = new FormData();
      formData.set("file", selectedFile);
      UserProfilesAPI.set_user_profile_image(formData)
        .then((response) => {
          getSetUserProfile();
          handleClose();
        })
        .catch((error) => {
          console.log(error);
        });
    }
    if (firstName !== userData.first_name || lastName !== userData.last_name) {
      UserProfilesAPI.update_name({
        user: {
          first_name: firstName,
          last_name: lastName,
        },
      })
        .then((res) => {
          getSetUserProfile();
          disableUpdating();
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (signinEmail !== userData.email) {
      UserProfilesAPI.update_user_email({
        user: {
          email: signinEmail,
        },
      })
        .then((res) => {
          dispatch(
            setVerified({
              verified: false,
            })
          );
          getSetUserProfile();
          setEmailError(false);
          setChangeSuccess(true);
          setTimeout(() => {
            setChangeSuccess(false);
          }, 3000);
        })
        .catch((err) => {
          if (err.request.status === 403) {
            setEmailError(true);
          }
          console.log(err);
        });
    }
  };
  const handleUpdatePassword = () => {
    if (!currentPassword || !newPassword || !confirmPassword) {
      setEmpty(true);
      setAlert(true);
      setType("warning");
      setMessage("Fields Cannot be blank");
      setTimeout(() => {
        setAlert(false);
      }, 3000);
    } else {
      if (newPassword !== confirmPassword) {
        setEmpty(false);
        setNotMatched(true);
      } else {
        setUpdatingPassword(true);
        SignInAPI.updatePassword({
          user: {
            current_password: currentPassword,
            password: newPassword,
            password_confirmation: confirmPassword,
          },
        })
          .then((res) => {
            setCurrentWrong(false);

            setUpdatedPassword(true);
            setTimeout(() => {
              setUpdatedPassword(false);
            }, 3000);
            setUpdatingPassword(false);
          })
          .catch((err) => {
            setUpdatingPassword(false);
            setCurrentWrong(true);
            console.log(err);
          });
      }
    }
  };
  const handleSendVerification = () => {
    UserProfilesAPI.send_verification_code()
      .then((res) => {
        setVerificationSent(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (newPassword === confirmPassword) {
      setNotMatched(false);
      setNotMatchedMessage(false);
    } else {
      setNotMatchedMessage(true);
    }
    if (newPassword.length < 6) {
      setMinPassword(true);
    } else {
      setMinPassword(false);
    }
  }, [newPassword, confirmPassword]);

  const { t } = useTranslation();

  return (
    <>
      <div onClick={handleOpen} className="profile-img">
        <img
          style={{
            width: "46px",
            height: "46px",
            borderRadius: "50%",
            verticalAlign: "middle",
          }}
          src={userImg}
          // src={user_profile?.user_img}
          alt="UserProfile"
        />
      </div>
      <div
        style={{
          margin: "0px 0 0px 10px",
        }}
        className="profile-typo-innner justified"
      >
        <div
          style={{
            cursor: "pointer",
          }}
          className="userName"
        >
          <div
            // onClick={runThis}
            style={{ color: "white", fontWeight: "500", fontSize: "13px" }}
            onClick={handleOpen}
            className={UniqueClass}
          >
            {Text.substring(0, 10)}
            {Text.length > 10 && "..."}
          </div>
        </div>
      </div>
      <Modal
        open={profileModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="userProfileBox modals-height-on-mobiles" sx={style}>
          <ShowComponent condition={showData}>
            <div
              style={{
                width: "100%",
                height: "100%",
                background: "#1e2024",
                transition: ".3s",
                zIndex: "100",
                left: slider ? "0px" : "540px",
                overflowY: "auto",
                height: "100%",
              }}
              className="userEdit absolute modals-height-on-mobiles"
            >
              <div className="userEdit-content">
                <div
                  style={{
                    background: "#17181b",
                    height: "50px",
                    padding: "0px 10px",
                    boxSizing: "border-box",
                  }}
                  className="userProfileEdit-top width my_row space-between"
                >
                  <div className="profile-edit-top-inner">
                    <div className="userProfile-editLeft justified">
                      <button
                        className="userProfile-editLeft secondary_text"
                        onClick={slideBack}
                      >
                        <ChevronLeftIcon />
                      </button>
                      <span
                        style={{
                          marginLeft: "5px",
                        }}
                        className="orbitron-font"
                      >
                        {t("header.userProfile.editPage.nickname")}
                      </span>
                    </div>
                  </div>

                  <div className="userProfile-editRight secondary_text">
                    <div>
                      <button
                        onClick={handleClose}
                        className="UserModal-closeButton"
                      >
                        <svg
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                          className="secondary_text"
                        >
                          <g data-name="Layer 2">
                            <g data-name="close">
                              <rect
                                width="24"
                                height="24"
                                transform="rotate(180 12 12)"
                                opacity="0"
                              />
                              <path d="M13.41 12l4.3-4.29a1 1 0 1 0-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 0 0-1.42 1.42l4.3 4.29-4.3 4.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l4.29-4.3 4.29 4.3a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42z" />
                            </g>
                          </g>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>

                <div className="userProfile-edit-data-collections">
                  <div
                    style={{
                      margin: "20px auto 20px",
                      // width: "80%",
                    }}
                    className="userProfile-edit-image relative justified"
                  >
                    {editableProfile && (
                      <div
                        style={{
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                        className="image-capture absolute"
                      >
                        <input
                          style={{
                            width: "100%",
                            height: "100%",
                            opacity: "0%",
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                          }}
                          type="file"
                          name="file"
                          value={updatedImage}
                          onChange={changeHandler}
                          accept="image/*"
                        />
                        <AddCircleIcon />
                      </div>
                    )}

                    {selectedFile ? (
                      <img
                        style={{
                          borderRadius: "50%",

                          width: "150px",
                          height: "150px",
                        }}
                        src={preview}
                      />
                    ) : (
                      <img
                        style={{
                          borderRadius: "50%",

                          width: "150px",
                          height: "150px",
                        }}
                        src={selected_user_profile?.user_img}
                        alt=""
                      />
                    )}
                  </div>

                  <div
                    style={{
                      margin: "0 auto",
                    }}
                    className="userProfile-userName-edit width-80"
                  >
                    <div className="edit-profile-field-fregment-container">
                      <div className="my_row space-between width">
                        <span
                          // style={{ marginLeft: "5px" }}
                          className={editableProfile ? "white" : "disabled"}
                        >
                          {t("header.userProfile.editPage.username")}
                        </span>

                        <div
                          // style={{
                          //   width: "17%",
                          // }}
                          className="enable-edit-icons-container my_row space-between"
                        >
                          <button
                            className={`${
                              editableProfile ? "white" : "text_color"
                            }`}
                            onClick={() => {
                              editableProfile
                                ? setEditableProfile(false)
                                : setEditableProfile(true);
                            }}
                          >
                            <Tooltip
                              title={t(
                                "header.userProfile.editPage.editMyProfile"
                              )}
                              placement="left"
                              arrow
                            >
                              <EditIcon />
                            </Tooltip>
                          </button>
                          <button
                            className={
                              editablePassword
                                ? "white margin-left-10"
                                : "text_color margin-left-10"
                            }
                            onClick={() => {
                              editablePassword
                                ? setEditablePassword(false)
                                : setEditablePassword(true);
                            }}
                          >
                            <Tooltip
                              title={t(
                                "header.userProfile.editPage.updateMyPassword"
                              )}
                              placement="top"
                              arrow
                            >
                              <KeyIcon />
                            </Tooltip>
                          </button>
                        </div>
                      </div>
                      <div className="userNameEditor">
                        <input
                          className={`width bold ${
                            editableProfile ? "white" : "disabled"
                          }`}
                          style={{
                            margin: "10px 0px 0px 0px",
                            padding: "0px 15px",
                            height: "40px",
                            background: "rgb(36, 38, 43)",
                            border: "solid 1px #3d4046",
                            borderRadius: "3px",
                            fontWeight: "400",
                          }}
                          type="text"
                          value={updatedUserName}
                          placeholder={t(
                            "header.userProfile.editPage.usernamePlaceholder"
                          )}
                          onChange={(e) => {
                            setUpdatedUsername(e.target.value);
                          }}
                          disabled={editableProfile ? false : true}
                        />
                      </div>
                    </div>

                    {editableProfile && (
                      <div className="userNameTypo">
                        <span
                          className="text_color"
                          style={{
                            fontSize: "11px",
                            marginBottom: "10px",
                            display: "block",
                            textAlign: "left",
                          }}
                        >
                          {t("header.userProfile.editPage.doNotUse")}
                        </span>
                      </div>
                    )}

                    <div className="user-firstName-lastName-container width my_row space-between">
                      <div className="edit-profile-field-fregment-container width">
                        <div className="my_row space-between width">
                          <span
                            style={{ marginLeft: "5px" }}
                            className={editableProfile ? "white" : "disabled"}
                          >
                            {t("header.userProfile.editPage.fName")}
                          </span>
                        </div>
                        <div className="fullnameEditor">
                          <input
                            className={`width bold ${
                              editableProfile ? "white" : "disabled"
                            }`}
                            style={{
                              margin: "10px 0px 0px 0px",
                              padding: "0px 15px",
                              height: "40px",
                              background: "rgb(36, 38, 43)",
                              border: "solid 1px #3d4046",
                              borderRadius: "3px",
                              fontWeight: "400",
                            }}
                            type="text"
                            value={firstName}
                            placeholder={t("header.userProfile.editPage.fName")}
                            onChange={(e) => {
                              setFirstName(e.target.value);
                            }}
                            disabled={editableProfile ? false : true}
                          />
                        </div>
                      </div>
                      <div className="edit-profile-field-fregment-container width">
                        <div className="my_row space-between width">
                          <span
                            style={{ marginLeft: "5px" }}
                            className={editableProfile ? "white" : "disabled"}
                          >
                            {t("header.userProfile.editPage.lName")}
                          </span>
                        </div>
                        <div className="fullnameEditor">
                          <input
                            className={`width bold ${
                              editableProfile ? "white" : "disabled"
                            }`}
                            style={{
                              margin: "10px 0px 0px 0px",
                              padding: "0px 15px",
                              height: "40px",
                              background: "rgb(36, 38, 43)",
                              border: "solid 1px #3d4046",
                              borderRadius: "3px",
                              fontWeight: "400",
                            }}
                            type="text"
                            value={lastName}
                            placeholder={t("header.userProfile.editPage.lName")}
                            onChange={(e) => {
                              setLastName(e.target.value);
                            }}
                            disabled={editableProfile ? false : true}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="edit-profile-field-fregment-container">
                      <div className="width my_row space-between">
                        <span
                          style={{ marginLeft: "5px" }}
                          className={
                            editableProfile
                              ? emailError
                                ? "error"
                                : "white"
                              : "disabled"
                          }
                        >
                          {t("header.userProfile.editPage.signinEmail")}{" "}
                          {emailError && "*"}
                        </span>
                        {changeSuccess && (
                          <span
                            style={{
                              fontSize: "12px",
                            }}
                            className="success"
                          >
                            {t(
                              "header.userProfile.editPage.emailChangeSuccess"
                            )}
                          </span>
                        )}
                        {emailError && (
                          <span
                            style={{
                              fontSize: "12px",
                            }}
                            className="error"
                          >
                            {t("header.userProfile.editPage.emailchangeError")}
                          </span>
                        )}
                      </div>
                      <div
                        className="userNameEditor relative"
                        style={{ marginBottom: "40px" }}
                      >
                        <div
                          style={{
                            bottom: "8px",
                            left: "10px",
                          }}
                          className="email-status-container absolute"
                        >
                          {verified ? (
                            <Tooltip
                              title={t(
                                "header.userProfile.editPage.verifiedEmail"
                              )}
                              placement="top"
                              arrow
                            >
                              <VerifiedUserIcon className="success" />
                            </Tooltip>
                          ) : (
                            <Tooltip
                              title={t(
                                "header.userProfile.editPage.notVerifiedEmail"
                              )}
                              placement="top"
                              arrow
                            >
                              <GppMaybeIcon className="error" />
                            </Tooltip>
                          )}
                        </div>
                        <div
                          style={{
                            bottom: "8px",
                            right: "10px",
                          }}
                          className="email-status-container absolute"
                        >
                          {!verified && (
                            <button onClick={handleSendVerification}>
                              {verificationSent ? (
                                <Tooltip
                                  title={t(
                                    "header.userProfile.editPage.verificationLinkSent"
                                  )}
                                  placement="top"
                                  arrow
                                >
                                  <DoneIcon className="success" />
                                </Tooltip>
                              ) : (
                                <Tooltip
                                  title={t(
                                    "header.userProfile.editPage.sendVerificationLink"
                                  )}
                                  placement="top"
                                  arrow
                                >
                                  <SendIcon className="white" />
                                </Tooltip>
                              )}
                            </button>
                          )}
                        </div>
                        <input
                          className={`width bold ${
                            editableProfile ? "white" : "disabled"
                          }`}
                          style={{
                            margin: "10px 0px 0px 0px",
                            padding: "0px 15px 0px 35px",
                            height: "40px",
                            background: "rgb(36, 38, 43)",
                            border: `solid 1px ${
                              emailError ? "#ff0000" : "#3d4046"
                            }`,
                            borderRadius: "3px",
                            fontWeight: "400",
                            outlineColor: emailError ? "#ff0000" : "#357ccc",
                          }}
                          type="text"
                          value={signinEmail}
                          placeholder={t(
                            "header.userProfile.editPage.enteryremail"
                          )}
                          onChange={(e) => {
                            setSigninEmail(e.target.value);
                          }}
                          disabled={editableProfile ? false : true}
                        />
                      </div>
                    </div>
                    {editableProfile && (
                      <div className="userNameTYpo">
                        <span
                          className="text_color"
                          style={{
                            fontSize: "11px",
                            marginBottom: "10px",
                            display: "block",
                            textAlign: "left",
                          }}
                        >
                          {t("header.userProfile.editPage.validEmail")}
                        </span>
                      </div>
                    )}

                    {/* <div className="edit-profile-field-fregment-container">
                    <div>
                      <span
                        style={{ marginLeft: "5px" }}
                        className={editableProfile ? "white" : "disabled"}
                      >
                        Name
                      </span>
                    </div>
                    <div className="userNameEditor">
                      <input
                        className={`width bold ${
                          editableProfile ? "white" : "disabled"
                        }`}
                        style={{
                          margin: "10px 0px 5px 0",
                          padding: "0px 15px",
                          height: "40px",
                          background: "rgb(36, 38, 43)",
                          border: "solid 1px #3d4046",
                          borderRadius: "3px",
                          fontWeight: "400",
                        }}
                        type="text"
                        value=""
                        onChange={(e) => {
                          console.log("Field on change");
                        }}
                        placeholder="Enter Your Name"
                        disabled={editableProfile ? false : true}
                      />
                    </div>
                  </div>
                  <div className="edit-profile-field-fregment-container">
                    <div>
                      <span
                        style={{ marginLeft: "5px" }}
                        className={editableProfile ? "white" : "disabled"}
                      >
                        Email
                      </span>
                    </div>
                    <div className="userNameEditor">
                      <input
                        className={`width bold ${
                          editableProfile ? "white" : "disabled"
                        }`}
                        style={{
                          margin: "10px 0px 5px 0",
                          padding: "0px 15px",
                          height: "40px",
                          background: "rgb(36, 38, 43)",
                          border: "solid 1px #3d4046",
                          borderRadius: "3px",
                          fontWeight: "400",
                        }}
                        type="text"
                        value=""
                        onChange={(e) => {
                          console.log("Field on change");
                        }}
                        placeholder="Enter Your E-mail"
                        disabled={editableProfile ? false : true}
                      />
                    </div>
                  </div>
                      */}

                    {editableProfile && (
                      <div className="button-confirm width-90 justified">
                        <button
                          style={{
                            height: "30px",
                            height: "55px",
                            margin: "20px auto",
                          }}
                          className="primary-btn primary-btn-bg"
                          onClick={handlePressModify}
                        >
                          {isUpdating ? (
                            <div
                              style={{
                                top: "-46px",
                              }}
                              className="lds-ellipsis"
                            >
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>
                          ) : (
                            t("header.userProfile.editPage.modify")
                          )}
                        </button>
                      </div>
                    )}

                    <div className="password-editor-container">
                      <div className="password-editor-container-content">
                        {editablePassword && (
                          <div className="editable-password-fields-container">
                            <div className="edit-profile-field-fregment-container">
                              <div className="title-eye-container width my_row space-between">
                                <span
                                  style={{ marginLeft: "5px" }}
                                  className={`${
                                    empty && !currentPassword
                                      ? "error"
                                      : "white"
                                  }`}
                                >
                                  {t(
                                    "header.userProfile.editPage.currentPassword"
                                  )}

                                  {empty && !currentPassword && "*"}
                                </span>
                                <button
                                  className={canSee ? "white" : "text_color"}
                                  onClick={() => {
                                    canSee ? setCanSee(false) : setCanSee(true);
                                  }}
                                >
                                  <Tooltip
                                    title={
                                      canSee
                                        ? t(
                                            "header.userProfile.editPage.hidePass"
                                          )
                                        : t(
                                            "header.userProfile.editPage.showPass"
                                          )
                                    }
                                    placement="left"
                                    arrow
                                  >
                                    {canSee ? (
                                      <VisibilityIcon />
                                    ) : (
                                      <VisibilityOffIcon />
                                    )}
                                  </Tooltip>
                                </button>
                              </div>
                              <div className="currentPasswordEditor">
                                <input
                                  className="width bold white"
                                  style={{
                                    margin: "10px 0px 5px 0",
                                    padding: "0px 15px",
                                    height: "40px",
                                    background: "rgb(36, 38, 43)",
                                    border: `solid 1px ${
                                      empty && !currentPassword
                                        ? "#ff0000"
                                        : "#3d4046"
                                    }`,
                                    borderRadius: "3px",
                                    fontWeight: "400",
                                    outlineColor:
                                      empty && !currentPassword
                                        ? "#ff0000"
                                        : "#357ccc",
                                  }}
                                  type={canSee ? "text" : "password"}
                                  value={currentPassword}
                                  onChange={(e) => {
                                    setCurrentpassword(e.target.value);
                                  }}
                                  placeholder={t(
                                    "header.userProfile.editPage.currentPassPlaceholder"
                                  )}
                                />
                              </div>
                            </div>
                            <div className="edit-profile-field-fregment-container my_row space-between width">
                              <div className="password-fields-inner-fregment width">
                                <div>
                                  <span
                                    style={{ marginLeft: "5px" }}
                                    className={`${
                                      (empty && !newPassword) || notMatched
                                        ? "error"
                                        : "white"
                                    }`}
                                  >
                                    {t("header.userProfile.editPage.newPass")}
                                    {(empty && !newPassword) ||
                                      (notMatched && "*")}
                                  </span>
                                </div>
                                <div className="NewPasswordEditor">
                                  <input
                                    className={`width bold ${
                                      empty && !newPassword ? "error" : "white"
                                    }`}
                                    style={{
                                      margin: "10px 0px 5px 0",
                                      padding: "0px 15px",
                                      height: "40px",
                                      background: "rgb(36, 38, 43)",
                                      border: `solid 1px ${
                                        (empty && !newPassword) || notMatched
                                          ? "#ff0000"
                                          : "#3d4046"
                                      }`,
                                      outlineColor:
                                        (empty && !newPassword) || notMatched
                                          ? "#ff0000"
                                          : "#357ccc",
                                      borderRadius: "3px",
                                      fontWeight: "400",
                                    }}
                                    type={canSee ? "text" : "password"}
                                    value={newPassword}
                                    onChange={(e) => {
                                      setNewPassword(e.target.value);
                                    }}
                                    placeholder={t(
                                      "header.userProfile.editPage.newPassPlaceholder"
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="password-fields-inner-fregment width">
                                <div>
                                  <span
                                    style={{ marginLeft: "5px" }}
                                    className={`${
                                      (empty && !confirmPassword) || notMatched
                                        ? "error"
                                        : "white"
                                    }`}
                                  >
                                    {t(
                                      "header.userProfile.editPage.confirmPass"
                                    )}

                                    {(empty && !confirmPassword) ||
                                      (notMatched && "*")}
                                  </span>
                                </div>
                                <div className="NewPasswordEditor">
                                  <input
                                    className="width bold white"
                                    style={{
                                      margin: "10px 0px 5px 0",
                                      padding: "0px 15px",
                                      height: "40px",
                                      background: "rgb(36, 38, 43)",
                                      border: `solid 1px ${
                                        (empty && !confirmPassword) ||
                                        notMatched
                                          ? "#ff0000"
                                          : "#3d4046"
                                      }`,
                                      borderRadius: "3px",
                                      fontWeight: "400",
                                      outlineColor:
                                        (empty && !confirmPassword) ||
                                        notMatched
                                          ? "#ff0000"
                                          : "#357ccc",
                                    }}
                                    type={canSee ? "text" : "password"}
                                    value={confirmPassword}
                                    onChange={(e) => {
                                      setConfirmPassword(e.target.value);
                                    }}
                                    placeholder={t(
                                      "header.userProfile.editPage.confirmPass"
                                    )}
                                  />
                                </div>
                              </div>

                              {updatedPassword && (
                                <div className="notMatchedPassAlert width">
                                  <span
                                    style={{
                                      fontSize: "11px",
                                    }}
                                    className="success"
                                  >
                                    {t(
                                      "header.userProfile.editPage.passChangeSuccess"
                                    )}
                                  </span>
                                </div>
                              )}

                              {currentWrong && !updatedPassword && (
                                <div className="notMatchedPassAlert width">
                                  <span
                                    style={{
                                      fontSize: "11px",
                                    }}
                                    className="error"
                                  >
                                    {t(
                                      "header.userProfile.editPage.invalidCurrent"
                                    )}
                                  </span>
                                </div>
                              )}
                              {!updatedPassword && (
                                <div className="minlengthPassAlert width">
                                  <span
                                    style={{
                                      fontSize: "11px",
                                    }}
                                    className={
                                      !minPassword ? "success" : "text_color"
                                    }
                                  >
                                    {t(
                                      "header.userProfile.editPage.passLength"
                                    )}
                                  </span>
                                </div>
                              )}
                              {!updatedPassword && (
                                <div className="notMatchedPassAlert width">
                                  <span
                                    style={{
                                      fontSize: "11px",
                                    }}
                                    className={
                                      !notMatchedMessage &&
                                      newPassword &&
                                      confirmPassword
                                        ? "success"
                                        : "text_color"
                                    }
                                  >
                                    {t(
                                      "header.userProfile.editPage.passEquality"
                                    )}
                                  </span>
                                </div>
                              )}

                              <div className="update-password-button-container width justified">
                                {minPassword ? (
                                  <button
                                    style={{
                                      height: "30px",
                                      height: "55px",
                                      margin: "20px auto",
                                    }}
                                    className="primary-btn primary-btn-disable"
                                  >
                                    {t("header.referral.update")}
                                  </button>
                                ) : (
                                  <button
                                    style={{
                                      height: "30px",
                                      height: "55px",
                                      margin: "20px auto",
                                    }}
                                    className="primary-btn primary-btn-bg"
                                    onClick={handleUpdatePassword}
                                  >
                                    {updatingPassword ? (
                                      <div
                                        style={{
                                          top: "-46px",
                                        }}
                                        className="lds-ellipsis"
                                      >
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                      </div>
                                    ) : (
                                      "Update"
                                    )}
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="userProfile-main">
              <div className="userProfile-inner">
                <div className="userProfileContent">
                  <div
                    style={{ padding: "15px 0 10px" }}
                    className="profileContent-topDiv my_row space-between"
                  >
                    <div className="topTextContainer">
                      <span className="orbitron-font">
                        {t("header.userProfile.frontPage.userInformation")}
                      </span>
                    </div>

                    <button
                      onClick={handleClose}
                      className="UserModal-closeButton"
                    >
                      <svg
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g data-name="Layer 2">
                          <g data-name="close">
                            <rect
                              width="24"
                              height="24"
                              transform="rotate(180 12 12)"
                              opacity="0"
                            />
                            <path d="M13.41 12l4.3-4.29a1 1 0 1 0-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 0 0-1.42 1.42l4.3 4.29-4.3 4.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l4.29-4.3 4.29 4.3a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42z" />
                          </g>
                        </g>
                      </svg>
                    </button>
                  </div>
                  <div
                    style={{
                      margin: "10px 0px",
                    }}
                    className="user-profile-data justified relative"
                  >
                    <div className="user-profile-data-inner">
                      {/* <ReferralModal /> */}

                      <Tooltip
                        title={t("header.userProfile.editPage.editMyProfile")}
                        placement="left"
                      >
                        <button
                          style={{
                            right: "0px",
                            padding: "3px",
                            background: "#202020",
                            width: "30px",
                            height: "30px",
                            borderRadius: "3px",
                          }}
                          className="userProfile-edit absolute secondary_text"
                          onClick={slideFunction}
                        >
                          <EditIcon style={{ fontSize: "18px" }} />
                        </button>
                      </Tooltip>

                      <div className="user-profile-img justified relative">
                        <UserProfileImg src={selected_user_profile?.user_img} />
                      </div>
                      <div className="user-name-id">
                        <div className="user-name-container centered_text">
                          <span>{Text}</span>
                        </div>

                        <div className="user-id-container centered_text">
                          <span>
                            {t("header.userProfile.frontPage.userId")}:{" "}
                            {selected_user_profile?.user_id}
                          </span>
                        </div>
                      </div>
                      {/* <div className="">
                      <Tooltip title="Your referal code" placement="bottom">
                        <div
                          style={{
                            cursor: "pointer",
                          }}
                          className="user-stats justified"
                        >
                          <span className="svgColor justified orbitron-font">
                            <AddLinkIcon />
                            {t("header.userProfile.frontPage.statistics")}
                            <div
                              style={{
                                textAlign: "center",
                                fontWeight: "600",
                                fontSize: "18px",
                              }}
                              className="totals-bets-count"
                            >
                              <button
                                className="text_color"
                                onClick={() =>
                                  handleCopy(
                                    selected_user_profile?.referal_link
                                  )
                                }
                              >
                                <span>
                                  {selected_user_profile?.referal_link}
                                </span>{" "}
                                &nbsp;
                                {copy
                                  ? t("header.wallet.depositTab.copied")
                                  : t("header.wallet.depositTab.copy")}
                              </button>
                            </div>
                          </span>
                        </div>
                      </Tooltip>
                    </div> */}
                    </div>
                  </div>

                  <div className="userProfile-stats-main for-margin-UserProfile">
                    <div className="userProfile-stats-inner">
                      <div className="userProfile-stats-top my_row space_between">
                        <div className="user-stats justified">
                          <span className="svgColor justified orbitron-font">
                            <SignalCellularAltIcon />
                            {t("header.userProfile.frontPage.statistics")}
                          </span>
                        </div>
                      </div>
                      <div className="userProfile-stats-container">
                        <div className="user-total-wins single-state ">
                          <div className="total-wins justified ">
                            <span className="statsIcon justified">
                              <CasinoIcon />
                            </span>
                            <span
                              style={{
                                color: "rgba(153, 164, 176, 0.6)",
                              }}
                            >
                              {t("header.userProfile.frontPage.totalWins")}
                            </span>
                          </div>
                          <div
                            style={{
                              textAlign: "center",
                              fontWeight: "600",
                              fontSize: "18px",
                            }}
                            className="totals-wins-count"
                          >
                            <span>{selected_user_profile?.total_wins}</span>
                          </div>
                        </div>
                        <div className="user-total-bets single-state">
                          <div className="total-bets justified">
                            <span className="statsIcon justified golden ">
                              <MonetizationOnIcon />
                            </span>
                            <span
                              style={{
                                color: "rgba(153, 164, 176, 0.6)",
                              }}
                            >
                              {t("header.userProfile.frontPage.totalBets")}
                            </span>
                          </div>
                          <div
                            style={{
                              textAlign: "center",
                              fontWeight: "600",
                              fontSize: "18px",
                            }}
                            className="totals-bets-count"
                          >
                            <span>{selected_user_profile?.total_bets}</span>
                          </div>
                        </div>
                        <div className="user-total-wagered single-state">
                          <div className="total-bets justified">
                            <span className="statsIcon justified golden ">
                              <img
                                style={{
                                  maxWidth: "16px",
                                  marginRight: "7px",
                                }}
                                src={CoinStack}
                              />
                            </span>
                            <span
                              style={{
                                color: "rgba(153, 164, 176, 0.6)",
                              }}
                            >
                              {t("header.userProfile.frontPage.totalWagered")}
                            </span>
                          </div>
                          <div
                            style={{
                              textAlign: "center",
                              fontWeight: "600",
                              fontSize: "18px",
                            }}
                            className="totals-bets-count"
                          >
                            <span>
                              ${selected_user_profile?.wagered_amount}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="userProfile-user-joining">
                    <span style={{ fontSize: "12px" }}>
                      {t("header.userProfile.frontPage.joinAt")}
                      {" " +
                        new Date(
                          selected_user_profile?.created_at
                        ).toLocaleDateString("en-US")}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </ShowComponent>
          <ShowComponent condition={!showData}>
            <div
              style={{
                left: "165px",
                position: "absolute",
                top: "196px",
                zIndex: "1",
              }}
              className="lds-ellipsis"
            >
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </ShowComponent>
        </Box>
      </Modal>
    </>
  );
}
export default memo(BasicModal);
