import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { betPriceContext } from "../../../Contexts/BetPrice";
import ShowComponent from "../../../Common/ShowComponent/ShowComponent";

const ShareAbleField = ({
  labelTwoActive,
  secondLabel,
  secondLabelValue,
  currencyIcon,
  rangeSlider,
  showSlider,
  label,
  name,
  type,
  placeholder,
  value,
  handleOnChange,
  onBlur,
  addOns,
  addOnsHandle,
  sliderButtonHandle,
  disabled,
  min,
  max,
}) => {
  const { t } = useTranslation();
  const { activatedCurrency } = useContext(betPriceContext);

  return (
    <div className="mt-4 mb-4">
      <div className="">
        <div className="d-flex justify-content-between mb-2 fs-14 secondary_text">
          <p className="mb-0">{label} &nbsp;</p>
          <ShowComponent condition={labelTwoActive}>
            <p className="mb-0">
              <span className="blue_color">{secondLabelValue}</span>
              &nbsp;{secondLabel}
            </p>
          </ShowComponent>
        </div>

        <div className="position-relative">
          <ShowComponent condition={currencyIcon}>
            <img
              src={`/imgs/AcceptedNetworks/${activatedCurrency?.network_id}.webp`}
              alt={activatedCurrency?.network_id}
              className="ms-3 position-absolute currency-icons top-50 translate-middle-y"
            />
          </ShowComponent>

          <ShowComponent condition={addOns.length}>
            <div className="d-flex end-0 me-2 position-absolute top-50 translate-middle-y">
              {addOns.map((addOn) => (
                <button
                style={{position:"relative", zIndex:"-18"}}
                  name={addOn.name}
                  className="dark-bg-one ms-1 p-2 radius-3 secondary_text cursor-pointer "
                  disabled={disabled}
                  onClick={addOnsHandle}
                >
                  {addOn.contentType === "text" ? (
                    addOn.content
                  ) : (
                    <img src={addOn.content} alt="" style={{ width: "10px", zIndex:"-18",position:"relative" }} />
                  )}
                </button>
              ))}
            </div>
          </ShowComponent>

          <input
            type={type}
            name={name}
            className={`${
              disabled ? "form-control-disabled" : "form-control"
            } ${currencyIcon && "ps-5"}`}
            placeholder={placeholder}
            value={value}
            onChange={handleOnChange}
            onBlur={onBlur}
            disabled={disabled}
          />
        </div>
      </div>
      <ShowComponent condition={rangeSlider}>
        <ShowComponent condition={showSlider}>
          <div className={`mt-3 d-flex gap-3 align-items-center`}>
            <button
              name="minBetAmount"
              className="dark-bg-one fs-13 p-2 radius-3 secondary_text"
              disabled={disabled}
              onClick={sliderButtonHandle}
            >
              {t("header.wallet.withdrawTab.min")}
            </button>
            <input
              type="range"
              name={name}
              className="form-range"
              id="customRange1"
              step="0.000001"
              value={value}
              onChange={handleOnChange}
              min={min}
              max={max}
              disabled={disabled}
            />
            <button
              name="maxBetAmount"
              className="dark-bg-one fs-13 p-2 radius-3 secondary_text"
              disabled={disabled}
              onClick={sliderButtonHandle}
            >
              {t("header.wallet.swapTab.max")}
            </button>
          </div>
        </ShowComponent>
      </ShowComponent>
    </div>
  );
};

export default ShareAbleField;
