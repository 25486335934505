import React, { useEffect, useState, memo } from "react";
import KenoBox from "./KenoBox/KenoBox";
import ShareAblePlayArea from "../Shareables/ShareAblePlayArea/ShareAblePlayArea";
import MutualMods from "./Mods/MutualMods";
import Undermaintenance from "../../Common/Undermaintenance";
import KenoApis from "../../../api/Keno/keno";
import { useSelector } from "react-redux";
import BetStats from "../Limbo/Splitting/Stats/Stats";
import "./keno.css";
const Keno = () => {
  const user_id = useSelector((state) => state.user.currentUser?.user_id);
  const generateArray = (length) => {
    // alert("generating");
    let hashMap = [];

    for (let i = 0; i < length; i++) {
      let item = { type: "static", index: i + 1 };
      hashMap[i + 1] = item;
    }

    return hashMap;
  };
  const inputValues = {
    betAmount: 1,
    risk: 0,
  };
  const [betValues, setBetValues] = useState(inputValues);
  const [kenoBoxes, setKenoBoxes] = useState(generateArray(40));
  const [selectionResults, setSelectionResults] = useState([]);
  const [selectedBoxes, setSelectedBoxes] = useState([]);
  const [myBets, setMyBets] = useState([]);
  const [bonusLine, setBonusLine] = useState([]);
  const [mode, setMode] = useState("manual");
  const [fetchingBaseLine, setFetchingBaseLine] = useState(false);
  const [serverMatchedLength, setServerMatchedLength] = useState(0);
  const [resultsFetched, setResultsFetched] = useState(false);
  const removeBox = (id) => {
    setSelectedBoxes(selectedBoxes.filter((item) => item !== id));
  };
  const addBox = (id) => {
    if (selectedBoxes.length < 10) {
      setSelectedBoxes((selectedBoxes) => [...selectedBoxes, id]);
    }
  };
  const filterHash = (id) => {
    if (selectedBoxes.includes(id)) {
      removeBox(id);
    } else {
      addBox(id);
    }
    setKenoBoxes(
      kenoBoxes.map((box, index) =>
        box.type === "static" ? box : { type: "static", index: index + 1 }
      )
    );
  };
  const boxAvaialableClasses = {
    keno_static: addBox,
    keno_clicked: removeBox,
    keno_diamond: filterHash,
    keno_lose: filterHash,
  };
  const selectBox = (id, className) => {
    boxAvaialableClasses[className](parseFloat(id));
  };
  const fetchMyBets = () => {
    KenoApis.my_bets()
      .then((res) => setMyBets(res.data.data.user_bets))
      .catch((err) => console.log(err));
  };
  const fetchBonusLine = () => {
    setFetchingBaseLine(true);
    KenoApis.base_line(selectedBoxes.length, betValues.risk)
      .then((res) => {
        setBonusLine(res.data.data.payout_line);
        setFetchingBaseLine(false);
      })
      .catch((err) => setFetchingBaseLine(false));
  };
  useEffect(() => {
    if (!selectedBoxes.length) {
      setBonusLine([]);
    } else {
      fetchBonusLine();
    }
  }, [selectedBoxes.length, betValues.risk]);
  useEffect(() => {
    if (user_id) {
      fetchMyBets();
    } else {
      setMyBets([]);
    }
  }, [user_id]);
  return (
    <div className="main-wrapper-container">
      <div className="row m-0">
        <div className="col-lg-9 mb-4 p-0 dark-bg-four">
          <div className="keno-game p-3 pb-0">
            <div className="align-content-center d-flex justify-content-start">
              <ul className="align-items-center d-flex flex-fill justify-content-end games-trends-bar gap-2 orbitron-font fs-14">
                {!myBets.length ? (
                  <li className="dark-bg-one">BET HISTORY</li>
                ) : (
                  myBets.map(
                    (bet, index) =>
                      index < 7 && (
                        <li
                          className={`${
                            bet.win_status ? "bg-blue" : "dark-bg-one"
                          }`}
                        >
                          {bet.cash_out}
                        </li>
                      )
                  )
                )}
              </ul>
            </div>
            <div class="keno-digits position-relative">
              {kenoBoxes.map((box) => (
                <KenoBox
                  selectedBoxes={selectedBoxes}
                  variant={box.type}
                  kenoBooxes={kenoBoxes}
                  onClick={(e) => selectBox(e.target.id, e.target.className)}
                  value={box.index}
                />
              ))}
            </div>
            <div class="keno-payout">
              {fetchingBaseLine ? (
                <div>Fetching Bonus Line</div>
              ) : !bonusLine.length ? (
                <div>Select 1 - 10 numbers to play</div>
              ) : (
                bonusLine.map((payout, index) => (
                  <div
                    style={{
                      backgroundColor:
                        resultsFetched && serverMatchedLength == index
                          ? "#274bab"
                          : "#24262b",
                    }}
                  >
                    {payout}x
                  </div>
                ))
              )}
            </div>
            <div class="keno-select">
              {selectedBoxes.length
                ? generateArray(selectedBoxes.length + 1).map((box, index) => (
                    <div
                      style={{
                        backgroundColor:
                          resultsFetched && serverMatchedLength == index - 1
                            ? "#274bab"
                            : "#24262b",
                      }}
                    >
                      {box.index - 1}&nbsp;Hits
                    </div>
                  ))
                : ""}
            </div>
          </div>
        </div>
        <div className="col-lg-3 mb-4 p-0 dark-bg-four order-lg-first">
          <div className="p-3 game-play-area">
            <ShareAblePlayArea
              modsAvaiable={true}
              mode={mode}
              setMode={setMode}
            >
              <MutualMods
                selectedBoxes={selectedBoxes}
                selectionResults={selectionResults}
                setSelectionResults={setSelectionResults}
                kenoBoxes={kenoBoxes}
                setKenoBoxes={setKenoBoxes}
                generateArray={generateArray}
                setSelectedBoxes={setSelectedBoxes}
                fetchMyBets={fetchMyBets}
                betValues={betValues}
                setBetValues={setBetValues}
                setServerMatchedLength={setServerMatchedLength}
                setResultsFetched={setResultsFetched}
              />
            </ShareAblePlayArea>
          </div>
        </div>
      </div>
      <div className="crashGame-history-container">
        <BetStats myBets={myBets} isLoading={false} />
      </div>
    </div>
  );
  return (
    <Undermaintenance
      type="soon"
      time=""
      support={false}
      p1="For our users to experience wonderful playtime,"
      p2="we are about to release new games. Stay tuned!"
    />
  );
};

export default memo(Keno);
