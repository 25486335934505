import React from "react";
import ShareAbleMode from "../ShareAbleMode/ShareAbleMode";
import ShowComponent from "../../../Common/ShowComponent/ShowComponent";

const ShareAblePlayArea = ({ modsAvaiable, children, mode, setMode }) => {
  const handleMode = (e) => {
    const { name } = e.target;
    setMode(name);
  };
  return (
    <>
      <ShowComponent condition={modsAvaiable}>
        <ShareAbleMode mode={mode} handleMode={handleMode} />
      </ShowComponent>
      {children}
    </>
  );
};

export default ShareAblePlayArea;
