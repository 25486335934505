import React from "react";
import ShowComponent from "../../../../Common/ShowComponent/ShowComponent";
const Cell = ({
  mineBlock,
  handleCheckBlock,
  mode,
  handleCheckAuto,
  selectedBoxes,
}) => {
  if (mode === "manual") {
    return (
      <div
        className={`mines-game-cell justified relative ${
          !mineBlock.open_status && "blur-block"
        } ${mineBlock.type} ${mineBlock.type}-icon`}
      >
        <ShowComponent condition={mineBlock.type === "mine"}>
          <div className="mines-game-blast"></div>
        </ShowComponent>
        <ShowComponent condition={!mineBlock.revealed}>
          <button
            id={mineBlock.id}
            className="absolute width height mines-game-box "
            onClick={handleCheckBlock}
          ></button>
        </ShowComponent>
        <ShowComponent condition={mineBlock.revealed}>
          <img
            src={`/assets/mines/svg/${mineBlock.type}.svg`}
            alt={mineBlock.type}
          />
        </ShowComponent>
      </div>
    );
  } else {
    return (
      <div
        className={`mines-game-cell justified relative ${
          !mineBlock.open_status && "blur-block"
        } ${mineBlock.type} ${mineBlock.type}-icon`}
      >
        <ShowComponent condition={mineBlock.type === "mine"}>
          <div className="mines-game-blast"></div>
        </ShowComponent>
        <ShowComponent condition={!mineBlock.revealed}>
          <button
            id={mineBlock.id}
             className="absolute width height mines-game-box"
            onClick={handleCheckAuto}
          ></button>
        </ShowComponent>
        <ShowComponent condition={mineBlock.revealed}>
          <img
            src={`/assets/mines/svg/${mineBlock.type}.svg`}
            alt={mineBlock.type}
          />
        </ShowComponent>
      </div>
    );
  }
};

export default Cell;
